import {
  Progress,
  Spin,
  Button,
  Popover,
  notification,
  Select,
  Input,
} from "antd";
import { useState, useEffect } from "react";
import {
  getAllLevel,
  updateEndOfTheYearLevel,
} from "../../store/services/learning";
import { GiProgression } from "react-icons/gi";

interface progressPercent {
  percent?: number;
  btn?: boolean;
  currentLevel?: string;
  level?: string;
  btnData?: string;
  link?: string;
  loading?: boolean;
  bg?: boolean;
  sideBtn?: boolean;
  activeSubject?: any;
  percentIncrease?: boolean;
  diffrenceInProgress?: any;
}

const SubjectLevel: React.FC<progressPercent> = ({
  percent,
  btn,
  currentLevel,
  level,
  btnData,
  link,
  loading,
  bg,
  sideBtn,
  activeSubject,
  percentIncrease,
  diffrenceInProgress,
}) => {
  const [open, setOpen] = useState(false);
  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  const TargetedLevel = () => {
    const [predictedlevel, setPredictedlevel] = useState();
    const [predictedlevelPercentage, setPredictedlevelPercentage] = useState();
    const [levels, setLevels] = useState([]);
    const fetchClasses = async () => {
      try {
        const res = await getAllLevel(activeSubject?.subjectId);
        setLevels(res.level);
      } catch (error) {
        console.log("error", error);
      }
    };
    useEffect(() => {
      fetchClasses();
    }, []);

    const updateLevelPercent = async () => {
      try {
        let payload = {
          // levelId,
          ...activeSubject,
          predictedlevel,
          predictedlevelPercentage,
        };
        const res = await updateEndOfTheYearLevel(payload);
        notification.success({ message: res.message });
        setOpen(false);
      } catch (error) {
        // notification.error({ message: error. });
        console.log("error", error);
      }
    };

    return (
      <div
        style={{
          width: 300,
        }}
      >
        <div className="popover_field">
          <div>Select level</div>
          <Select
            className="filter_select"
            placeholder="Select Class"
            onChange={(e) => {
              setPredictedlevel(e);
            }}
            optionFilterProp="children"
            value={predictedlevel}
          >
            {levels?.length > 0 &&
              levels?.map((item: any) => {
                return (
                  <Select.Option key={item.levelId} value={item.levelId}>
                    {item.levelName}
                  </Select.Option>
                );
              })}
          </Select>
        </div>
        <div className="popover_field">
          <div>Enter level percentage %</div>
          <Input
            className="filter_input"
            placeholder="Enter Percentage"
            onChange={(e: any) => {
              console.log("🚀 ~ levels?.map ~ e:", e.target.value);
              setPredictedlevelPercentage(e.target.value);
            }}
          />
        </div>
        <div className="popover_field">
          <Button className="submit-btn" onClick={updateLevelPercent}>
            Update
          </Button>
        </div>
      </div>
    );
  };

  return (
    <div
      className="learning_Card_child"
      style={{ backgroundColor: bg ? "#f9fafb" : "#fff" }}
    >
      {loading ? (
        <div className="student_subject_level_loading_box">
          <Spin className="student_custom_spinner" />
        </div>
      ) : (
        <div className="card_learning">
          <div style={{ display: "flex", gap: "24px", alignItems: "center" }}>
            <div className="pro_leaning">
              <Progress
                size={70}
                percent={percent}
                strokeWidth={10}
                strokeColor="var(--primary-color)"
                type="circle"
                trailColor="black"
                format={(percent) => `${percent}%`}
              />
            </div>
            <div className="card_learning_text">
              <p>{level}</p>
              <h1>{currentLevel}</h1>
            </div>
          </div>
          {percentIncrease ? (
            <Button>
              <GiProgression />{" "}
              <span style={{ margin: "0 .3rem" }}>
                {diffrenceInProgress}% last month{" "}
              </span>
            </Button>
          ) : (
            false
          )}
          {sideBtn ? (
            <Popover
              content={TargetedLevel}
              title=""
              trigger="click"
              open={open}
              onOpenChange={handleOpenChange}
            >
              <Button style={{ width: "max-content" }} type="primary">
                Set Targeted Level
              </Button>
            </Popover>
          ) : (
            false
          )}
        </div>
      )}
      {/* {
        btn &&
        <div>
          <Link to={link || "#"}>
            <span className="card_learning_btn">
              <FaArrowTrendUp color="#BDE556" />
              <span style={{ marginLeft: "10px" }}>{btnData}</span>
            </span>
          </Link>
        </div>
      } */}
    </div>
  );
};

export default SubjectLevel;
