import { Progress } from "antd";
import React from "react";

interface Props {
  data: any;
}
const ResultCard: React.FC<Props> = ({ data }) => {
  return (
    <div
      className="_resultCard"
      style={{
        backgroundColor:
          data?.progress <= 20
            ? "rgba(254, 243, 242, 1)"
            : data?.progress <= 40
            ? "rgba(255, 250, 235, 1)"
            : "rgba(236, 253, 243, 1)",
      }}
    >
      <p
        style={{
          color:
            data?.progress <= 20
              ? "rgba(217, 45, 32, 1)"
              : data?.progress <= 40
              ? "rgba(181, 71, 8, 1)"
              : "rgba(2, 122, 72, 1)",
        }}
      >
        {data?.subjectName}
      </p>
      <Progress
        type="circle"
        percent={Number(Number(data?.progress).toFixed(0))}
        size={40}
        strokeWidth={10}
        strokeColor={
          data?.progress <= 20
            ? "rgba(217, 45, 32, 1)"
            : data?.progress <= 40
            ? "rgba(247, 178, 122, 1)"
            : "rgba(166, 239, 103, 1)"
        }
        format={(percent) => `${percent}%`}
      />
    </div>
  );
};

export default ResultCard;
