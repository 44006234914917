import { notification } from "antd";
import { privateAPI } from "../../config/constants";
import store from "..";
import { setLoading, setReports } from "../slices/reportSlice";

export const createReport = async (paylaod: any) => {
  try {
    //   store.dispatch(setLoadingUnreadNotifications(true));
    const response = await privateAPI.post("/report/create", paylaod);
    if (response) {
      // store.dispatch(setAllLoadingNotifications(response));

      notification.success({
        message: response?.data?.message || "submit successfully",
      });
    }
    return response;
  } catch (error: any) {
    console.log(error);
    notification.error({
      message: error?.response?.data?.message || "Server error",
    });
  }
};

export const getStudentReports = async () => {
  try {
    const response = await privateAPI.post(`/report/get-student-reports`);
    if (response) {
      // console.log(response);
    }
    return response;
  } catch (error) {
    console.log(error);
  }
};
export const getStudentReportsById = async (data: any) => {
  try {
    const response = await privateAPI.post(`report/get-reports-by-id`, data);
    if (response) {
      // console.log(response);
    }
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const getStudentSubjectById = async (data: any) => {
  try {
    const response = await privateAPI.post(
      `student/get-student-subjects`,
      data
    );
    if (response) {
      // console.log(response);
    }
    return response;
  } catch (error) {
    console.log(error);
  }
};
export const getStudentCurrentLevel = async (data: any) => {
  try {
    const response = await privateAPI.post(
      `/student/get-student-current-level-progress-by-subject`,
      data
    );
    if (response) {
      // console.log(response);
    }
    return response;
  } catch (error) {
    console.log(error);
  }
};
export const getStudentUnArchiveTarget = async (data: any) => {
  try {
    const response = await privateAPI.post(`/student/un-achived-targets`, data);
    if (response) {
      // console.log(response);
    }
    return response;
  } catch (error) {
    console.log(error);
  }
};
export const getStudentProgressDifferenceFromLastMonth = async (data: any) => {
  try {
    const response = await privateAPI.post(`/student/get-student-performance-difference`, data);
    if (response) {
      console.log(response);
    }
    return response;
  } catch (error) {
    console.log(error);
  }
};
export const getStudentReport = async (reportId: any) => {
  try {
    const response = await privateAPI.get(`/report/get-report/${reportId}`);
    if (response) {
      // console.log(response);
      return response?.data;
    }
    return response;
  } catch (error) {
    console.log(error);
  }
};
export const getOverAllProgress = async (data: any) => {
  try {
    const response = await privateAPI.post(
      `student/get-overall-progress`,
      data
    );
    if (response) {
      // console.log(response);
    }
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const getAllReports = async (
  search: any,
  classId: any,
  subjectid: any
) => {
  try {
    store.dispatch(setLoading(true));
    // const query =`?search=${filterState.search}&classId=${filterState.class}&subjectId=${filterState.subjectId}`
    let query = "";
    const condition = [];

    if (search) {
      condition.push(`search=${search}`);
    }
    if (classId) {
      condition.push(`classId=${classId}`);
    }
    if (subjectid) {
      condition.push(`subjectId=${subjectid}`);
    }
    if (condition?.length > 0) {
      query = `?${condition?.join("&")}`;
    }

    console.log("query", query);

    const response = await privateAPI.get(`/report/get-all${query}`);
    if (response) {
      // console.log(response);
      store.dispatch(setReports(response?.data?.reports));
      store.dispatch(setLoading(false));
      return response?.data;
    }
    return response;
  } catch (error) {
    store.dispatch(setLoading(false));
    notification.error({ message: "server error" });
    console.log(error);
  }
};

export const getOverPerformerData = async () => {
  try {
    const res = await privateAPI.get("/stats/class-with-over-performers");
    if (res.status === 200) {
      return res;
    }
  } catch (error) {
    console.log("error", error);
  }
};
export const getUnderPerformerData = async () => {
  try {
    const res = await privateAPI.get("/stats/class-with-under-performers");
    if (res.status === 200) {
      return res;
    }
  } catch (error) {
    console.log("error", error);
  }
};
export const getAboveTargetData = async () => {
  try {
    const res = await privateAPI.get("/stats/students-above-target");
    if (res.status === 200) {
      return res;
    }
  } catch (error) {
    console.log("error", error);
  }
};
export const getBelowTargetData = async () => {
  try {
    const res = await privateAPI.get("/stats/students-below-target");
    if (res.status === 200) {
      return res;
    }
  } catch (error) {
    console.log("error", error);
  }
};
