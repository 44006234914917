import { privateAPI } from "../../config/constants";
import store from "..";
import {
  createSubject,
  getSubjectbyclas,
  UpdateSubject,
} from "../interfaces/Subject/Subject";
import { notification } from "antd";
import { setSubject } from "../slices/Classes/classSlice";
import { addSubjects } from "../slices/Subject/subjectSlice";
import {
  setLoadingClasses,
  setSubjectsByClass,
  setSubjectListByClass,
} from "../slices/Classes/classSlice";
import {
  setLoadingSubjectList,
  setSubjectListByClass as setList,
} from "../slices/Subject/subjectListByClassSlice";
// import { error } from "console";
// // import { error } from "console";

export const addSubject = async (payload: createSubject) => {
  try {
    const response = await privateAPI.post("/subject/create", payload);
    if (response) {
      store.dispatch(addSubjects(response.data));
      notification.success({ message: "Subject created successfully" });
    }
    return response.data;
  } catch (error: any) {
    console.log(error);
    notification.error({
      message: error.response.data.message || "Server Error",
    });
  }
};

export const updateSubject = async (payload: UpdateSubject) => {
  try {
    const response = await privateAPI.post("/subject/update", payload);
    if (response) {
      notification.success({ message: "Subject update successfully" });
    }
    return response.data;
  } catch (error: any) {
    console.log(error);
    notification.error({
      message: error.response.data.message || "Server Error",
    });
  }
};

export const getSubjectByClass = async (payload: getSubjectbyclas) => {
  try {
    store.dispatch(setLoadingClasses(true));
    const res = await privateAPI.post(
      "/subject/get-subjects-from-class",
      payload
    );
    if (res) {
      store.dispatch(setSubjectsByClass(res?.data?.subjects));
    }
    return res.data;
  } catch (error) {
    console.error("Error fetching subjects:", error);
  } finally {
    store.dispatch(setLoadingClasses(false));
  }
};

export const getAllSubject = async (
  page: number,
  perPage: number,
  search?: string,
  status?: string,
  withMaqaasad?: Boolean
) => {
  try {
    store.dispatch(setLoadingClasses(true));
    const res = await privateAPI.post("/subject/get-all", {
      page: page,
      perPage: perPage,
      search: search || "",
      status: status,
      withMaqaasad,
    });
    return res?.data;
  } catch (error) {
    console.error("Error fetching subjects:", error);
  } finally {
    store.dispatch(setLoadingClasses(false));
  }
};

export const getSubjectAll = async (payload: any) => {
  try {
    store.dispatch(setLoadingClasses(true));
    const res = await privateAPI.post("/subject/get-all", payload);
    if (res) {
      store.dispatch(setSubject(res?.data?.subjectList));
    }
    return res.data;
  } catch (error) {
    console.error("Error fetching subjects:", error);
  } finally {
    store.dispatch(setLoadingClasses(false));
  }
};

export const getSubjectListByClassDropdown = async (payload: any) => {
  try {
    store.dispatch(setLoadingSubjectList(true));
    const res = await privateAPI.post(
      "/subject/get-subjects-from-class",
      payload
    );
    if (res) {
      store.dispatch(setList(res?.data?.subjects));
      store.dispatch(setSubjectListByClass(res?.data?.subjects));
    }
    return res.data;
  } catch (error) {
    console.error("Error fetching subjects:", error);
  } finally {
    store.dispatch(setLoadingSubjectList(false));
  }
};

export const getSubjectCurrentLevel = async (subjectId: number | string) => {
  try {
    const res = await privateAPI.get("/subject/get-subject-current-level");
    if (res) {
      return res;
    }
  } catch (error) {
    console.error("Error fetching subjects:", error);
  } finally {
    store.dispatch(setLoadingSubjectList(false));
  }
};

export const subArchive = async (data: any) => {
  try {
    await privateAPI.post("subject/archive", data);
  } catch (error) {
    console.error("Error creating class:", error);
    throw error;
  }
};
export const subUnArchive = async (data: any) => {
  try {
    await privateAPI.post("subject/unarchive", data);
  } catch (error) {
    console.error("Error creating class:", error);
    throw error;
  }
};
