import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ClassInfo } from "../../interfaces/Class/Class";
import { SubjectType } from "../../../types/interface/subject";
import { LevelType } from "../../../types/interface/level";

interface ClassState {
  classes: ClassInfo[];
  count: number;
  loading: boolean;
  subjects: SubjectType[];
  subjectListByClass: any;
  activeSubject: SubjectType | null;
  levels: any;
  activeFilter: string;
  searchStudent: any;
  currentLevel: any;
  currentIndex: number;
  subject: [];
}

const initialState: ClassState = {
  classes: [],
  count: 0,
  loading: true,
  subjects: [],
  subjectListByClass: [],
  activeSubject: null,
  levels: [],
  activeFilter: "full-class",
  searchStudent: null,
  currentLevel: [],
  currentIndex: -1,
  subject: [],
};

const classSlice = createSlice({
  name: "class",
  initialState,
  reducers: {
    setLoadingClasses(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setClasses(
      state,
      action: PayloadAction<{ records: ClassInfo[]; count: number }>
    ) {
      state.classes = action?.payload?.records;
      state.count = action?.payload?.count;
    },
    setSubjectsByClass(state, action) {
      const payload = {
        ...state,
        subjects: action.payload,
      };
      if (action?.payload?.length > 0) {
        payload.activeSubject = action.payload[0];
      }
      return {
        ...payload,
      };
    },
    setSubject(state, action) {
      state.subject = action.payload;
    },
    setSubjectListByClass(state, action) {
      state.subjectListByClass = action.payload;
    },

    setSearchQuery(state, action) {
      state.subject = action.payload;
    },

    setActiveFilter(state, action) {
      return {
        ...state,
        activeFilter: action.payload,
      };
    },
    setActiveSubject(state, action) {
      return {
        ...state,
        activeSubject: action.payload,
      };
    },
    setLevels(state, action) {
      return {
        ...state,
        levels: action.payload,
      };
    },

    setSearchStudent(state, action) {
      return {
        ...state,
        searchStudent: action.payload,
      };
    },
    setCurrentLevel(state, action) {
      return {
        ...state,
        currentLevel: action.payload,
      };
    },
    setCurrentIndex(state, action) {
      let index = -1;

      if (action.payload.type === "increment") {
        if (state.currentIndex === action.payload.length - 1) {
          index = 0;
        } else {
          index = state.currentIndex + 1;
        }
      } else if (action.payload.type === "decrement") {
        if (state.currentIndex === 0) {
          index = action.payload.length - 1;
        } else {
          index = state.currentIndex - 1;
        }
      } else {
        index = action.payload;
      }

      return {
        ...state,
        currentIndex: index,
      };
    },
  },
});

export const {
  setLoadingClasses,
  setClasses,
  setSubjectsByClass,
  setActiveSubject,
  setActiveFilter,
  setSubject,
  setLevels,
  setSearchStudent,
  setCurrentLevel,
  setCurrentIndex,
  setSubjectListByClass,
} = classSlice.actions;

export default classSlice.reducer;
