import store from "../store";

export const checkRoutePermissions = (value: string) => {
  const role = store.getState().auth?.user?.role;
  const roles = store.getState().auth?.user;

  const student = [
    "studentDashboard",

    // Learning
    "learningPage",

    // Reports
    "reportPage",

    // Notification
    "notificationPage",

    //// student Planner

    "studentPlanner",

    // Exams
    "examsPage",

    // // Student Planner
    // "studentPlannerPage",

    //// student report
    "StudentReport",

    // Setting
    "settingPage",
    "profileTab",
    "passwordTab",
  ];

  const teacher = [
    "teacherDashboard",

    // SCHEDULE
    "teacherSchedule",
    "viewSchedule",

    // STUDENT
    "studentPage",
    "viewStudent",
    "updateStudent",
    "deleteStudent",
    "assignSubjects",

    // Classes
    // "classesPage",
    "classesProgressPage",

    //    Reports
    "reportPage",

    "StudentReport",

    // Notification
    "notificationPage",

    // Exam
    "examsPage",

    // generate report

    "generateReport",

    // Setting
    "settingPage",
    "profileTab",
    "passwordTab",

    // Student Planner
    "studentPlanner",
    // "studentPlannerDetails",

    // Targets
    "Targets",
    // curriculumDetails
    "curriculumDetails",

    // Classes
    // "class",
    "classesPage",
    "classesProgressPage",

    //// support
    //Schudule
    "viewSchedule",
    "adminSchedule",
    "fileManager",
    "support",
  ];

  const admin = [
    "adminDashboard",

    // SCHEDULE
    "adminSchedule",
    "viewSchedule",
    "createSchedule",
    "updateSchedule",
    "deleteSchedule",

    // STUDENT
    "studentPage",
    "viewStudent",
    "createStudent",
    "updateStudent",
    "deleteStudent",
    "assignSubjects",

    // Classes

    "classesPage",
    "classesProgressPage",

    // Curriculum
    "curriculumPage",
    "curriculumDetails",

    // Subjects
    "subjects",

    // Reporting
    "reportingPage",
    // Reports
    "reportPage",
    "StudentReport",

    // Notification
    "notificationPage",

    // Teacher
    "teacherPage",

    // Monitoring
    "monitoringPage",

    // Assessments
    "assessmentsPage",
    "createAssessments",

    // Setting
    "settingPage",
    "profileTab",
    "passwordTab",
    "logoTab",
    "platformTab",
    "adminsTab",

    // Onboarding
    "onboardingPage",

    //Announcements
    "announcementsPage",

    "fileManager",

    //// support

    "support",
  ];

  if (role === "student") {
    // console.log("======> Student:", role);
    return student.includes(value) ? true : false;
  } else if (role === "teacher") {
    // console.log("======> Teacher:", role);
    return teacher.includes(value) ? true : false;
  } else if (role === "admin") {
    // console.log("======> Admin:", role);
    return admin.includes(value) ? true : false;
  } else {
    return student.includes(value) ? true : false;
  }
};
