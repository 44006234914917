import { Button, Menu, Space, Badge } from "antd";
import { IoLogOutOutline } from "react-icons/io5";
import React, { useCallback, useEffect, useState } from "react";
import "../../styles/pages/siderMenu.css";
import Anmouce from "../../assets/Anmouce.png";
import WhiteAnnoucemnet from "../../assets/WhiteAnnoucemnet.svg";
import Assessment from "../../assets/Assessment.png";
import WhiteAssessment from "../../assets/assesment-white.png";
import bag from "../../assets/bag.png";
import bagwhite from "../../assets/BagWhite.svg";
import Globe from "../../assets/Globe.png";
import globeWhite from "../../assets/GlobWhite.svg";
import monitoring from "../../assets/search.png";
import whiteMonitoring from "../../assets/WhiteMonitoring.svg";
import student from "../../assets/Students.png";
import studentWhite from "../../assets/WhiteStudent.svg";
import boardwhite from "../../assets/BoardWhite.svg";
import onboard from "../../assets/onboarding.png";
import whiteOnboarding from "../../assets/whiteOnboarding.svg";
import teacher from "../../assets/teachers.png";
import WhiteTeacher from "../../assets/WhiteTeacher.svg";
import seting from "../../assets/set.png";
import WhiteSetting from "../../assets/WhiteSetting.svg";
import msg from "../../assets/msg.png";
import WhiteMsg from "../../assets/WhiteMsg.svg";
import closeLogo from "../../assets/logoclose.png";
import profile from "../../assets/attachment.png";
import screen from "../../assets/screen.png";
import Notification from "../../assets/bell.png";
import Whitebell from "../../assets/WhiteBell.svg";
import shedule from "../../assets/shedule.png";
import WhiteSchedule from "../../assets/WhiteSchedule.svg";
import graph from "../../assets/graph.png";
import whiteGraph from "../../assets/WhiteReport.svg";
import Report from "../../assets/reports.png";
import Star from "../../assets/startIcon.png";
import StarBlack from "../../assets/startIconBlack.png";
import PlannerIcon from "../../assets/studentPlanner.png";
import { SiBookstack } from "react-icons/si";
import { MdFilePresent } from "react-icons/md";

import {
  MdOutlineKeyboardDoubleArrowLeft,
  MdOutlineKeyboardDoubleArrowRight,
} from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";

import LogoutModal from "../Modals/LogoutModal";
import { useAppSelector } from "../../store/hooks";
import { getNotificationsCount } from "../../store/services/notificationCounts";
import { checkRoutePermissions } from "../../utils/permission";
import { getUnreadMessages } from "../../store/services/supports";
import useBlocker from "../../Hook/useBlocker";
import { useSelector } from "react-redux";
import { setActiveProcess } from "../../store/slices/Classes/activeSlice";
import { useDispatch } from "react-redux";
import { PiGlobeHemisphereEastBold } from "react-icons/pi";
interface Props {
  visible?: boolean;
  setVisible?: (value: boolean) => void;
  collapsed?: boolean;
  setCollapsed?: (value: boolean) => void;
}

const SiderMenu: React.FC<Props> = ({
  visible,
  setVisible,
  collapsed,
  setCollapsed,
}) => {
  const user = useAppSelector((state) => state.auth.user);
  const { unreadCount } = useAppSelector((state) => state.notificationCount);

  const isActive = useSelector((state: any) => state.active.activeProcessing);
  const dispatch = useDispatch();
  useBlocker(!isActive, "You cannot navigate away. Please confirm.");

  const { unReadmesg } = useAppSelector((state) => state.support);

  const totalUnreadMessages = unReadmesg?.reduce(
    (acc: any, curr: any) => acc + (curr.unread || 0),
    0
  );

  const fetchCounts = async () => {
    await getUnreadMessages();
    await getNotificationsCount();
  };

  useEffect(() => {
    if (checkRoutePermissions("support")) {
      fetchCounts();
    }
  }, []);

  const headerHeight = 70;
  const footerHeight = 140;
  const { pathname } = useLocation();

  const getActiveKey = useCallback(() => {
    if (pathname.includes("/classes-progress/")) {
      return "Classes";
    }
    switch (pathname) {
      case "/":
        return "Dashboard";
      case "/teachers":
        return "Teachers";
      case "/settings":
        return "Settings";
      case "/subjects":
        return "Subjects";
      case "/classes":
        return "Classes";
      case "/class":
        return "Class";
      case "/targets":
        return "Targets";
      case "/reporting":
        return "Reporting";
      case "/schedule":
        return "Schedule";
      case "/curriculum":
        return "Curriculum";
      case "/monitoring":
        return "Monitoring";
      case "/students":
        return "Students";
      case "/assessment":
        return "Assessments";
      case "/announcement":
        return "Announcements";
      case "/file-manager":
        return "fileManager";
      case "/notifications":
        return "Notifications";
      case "/support":
        return "Support";
      case "/signuplink":
        return "Onboarding";
      case "student/planner":
        return "Student Panel";
      case "/reports":
        return "Reports";
      case "/learning":
        return "Learning";
      case "/exams":
        return "Exams";
      case "/student/planner":
        return "Student Planner";
      default:
        return "";
    }
  }, [pathname]);

  const getMenuItems = () => {
    let routes = [];

    routes.push({
      key: "Dashboard",
      icon: (
        <div className="sideBarMenu_itemImg_parent">
          <img src={active === "Dashboard" ? boardwhite : screen} alt="" />
        </div>
      ),
      label: "Dashboard",
    });
    if (checkRoutePermissions("learningPage")) {
      routes.push({
        key: "Learning",
        icon: (
          <div className="sideBarMenu_itemImg_parent">
            <img src={active === "Learning" ? globeWhite : Globe} alt="LN" />
          </div>
        ),
        label: "Learning",
      });
    }
    //@ts-ignore
    if (checkRoutePermissions("studentPlanner") && user.role === "teacher") {
      routes.push({
        key: "Student Planner",
        icon: (
          <div className="sideBarMenu_itemImg_parent">
            <img
              src={active === "studentPlanner" ? studentWhite : student}
              alt="SP"
            />
          </div>
        ),
        label: "Student Planner",
      });
    }
    if (checkRoutePermissions("classesPage")) {
      routes.push({
        key: "Classes",
        icon: (
          <div className="sideBarMenu_itemImg_parent">
            <img src={active === "Classes" ? bagwhite : bag} alt="C" />
          </div>
        ),
        label: "Classes",
      });
    }
    if (checkRoutePermissions("curriculumPage")) {
      routes.push({
        key: "Curriculum",
        icon: (
          <div className="sideBarMenu_itemImg_parent">
            {active === "Curriculum" ? (
              <PiGlobeHemisphereEastBold color="white" size={21} />
            ) : (
              <PiGlobeHemisphereEastBold size={21} />
            )}
          </div>
        ),
        label: "Curriculum",
      });
    }
    if (checkRoutePermissions("subjects")) {
      routes.push({
        key: "Subjects",
        icon: (
          <div className="sideBarMenu_itemImg_parent">
            {active === "Subjects" ? (
              <SiBookstack color="white" size={21} />
            ) : (
              <SiBookstack size={21} />
            )}
          </div>
        ),
        label: "Subjects",
      });
    }
    if (checkRoutePermissions("studentPage")) {
      routes.push({
        key: "Students",
        icon: (
          <div className="sideBarMenu_itemImg_parent">
            <img
              src={active === "Students" ? studentWhite : student}
              alt="ST"
            />
          </div>
        ),
        label: "Students",
      });
    }
    if (checkRoutePermissions("teacherPage")) {
      routes.push({
        key: "Teachers",
        icon: (
          <div className="sideBarMenu_itemImg_parent">
            <img src={active === "Teachers" ? WhiteTeacher : teacher} alt="T" />
          </div>
        ),
        label: "Teachers",
      });
    }
    if (checkRoutePermissions("Targets")) {
      routes.push({
        key: "Targets",
        icon: (
          <div className="sideBarMenu_itemImg_parent">
            <img src={active === "Targets" ? Report : Report} alt="TG" />
          </div>
        ),
        label: "Targets",
      });
    }
    if (checkRoutePermissions("reportingPage")) {
      routes.push({
        key: "Reporting",
        // icon: <GiSchoolBag size={iconSize} />,
        icon: (
          <div className="sideBarMenu_itemImg_parent">
            <img src={active === "Reporting" ? whiteGraph : graph} alt="R" />
          </div>
        ),
        label: "Reporting",
      });
    }
    if (checkRoutePermissions("monitoringPage")) {
      routes.push({
        key: "Monitoring",
        icon: (
          <div className="sideBarMenu_itemImg_parent">
            <img
              src={active === "Monitoring" ? whiteMonitoring : monitoring}
              alt="M"
            />
          </div>
        ),
        label: "Monitoring",
      });
    }
    if (checkRoutePermissions("adminSchedule")) {
      routes.push({
        key: "Schedule",
        icon: (
          <div className="sideBarMenu_itemImg_parent">
            <img
              src={active === "Schedule" ? WhiteSchedule : shedule}
              alt="SC"
            />
          </div>
        ),
        label: "Schedule",
      });
    }
    if (checkRoutePermissions("examsPage")) {
      routes.push({
        key: "Exams",
        icon: (
          <div className="sideBarMenu_itemImg_parent">
            <img src={active === "Exams" ? Star : StarBlack} alt="EX" />
          </div>
        ),
        label: "Exams",
      });
    }
    if (checkRoutePermissions("reportPage") && user?.role !== "admin") {
      routes.push({
        key: "Reports",
        icon: (
          <div className="sideBarMenu_itemImg_parent">
            <img src={active === "Reports" ? whiteGraph : graph} alt="RP" />
          </div>
        ),
        label: "Reports",
      });
    }
    if (checkRoutePermissions("studentPlannerPage")) {
      routes.push({
        key: "Student Planner",
        icon: (
          <div className="sideBarMenu_itemImg_parent">
            <img
              src={active === "Student Planner" ? PlannerIcon : PlannerIcon}
              alt="SP"
            />
          </div>
        ),
        label: "Student Planner",
      });
    }
    if (checkRoutePermissions("studentPlanner") && user?.role === "student") {
      routes.push({
        key: "Student Planner Details",
        icon: (
          <div className="sideBarMenu_itemImg_parent">
            <img
              src={
                active === "Student Planner Details" ? PlannerIcon : PlannerIcon
              }
              alt="SPD"
            />
          </div>
        ),
        label: "Student Planner Details",
      });
    }
    if (checkRoutePermissions("assessmentsPage")) {
      routes.push({
        key: "Assessments",
        icon: (
          <div className="sideBarMenu_itemImg_parent">
            <img
              src={active === "Assessments" ? WhiteAssessment : Assessment}
              alt="AS"
            />
          </div>
        ),
        label: "Assessments",
      });
    }
    if (checkRoutePermissions("announcementsPage")) {
      routes.push({
        key: "Announcements",
        icon: (
          <div className="sideBarMenu_itemImg_parent">
            <img
              src={active === "Announcements" ? WhiteAnnoucemnet : Anmouce}
              alt="AN"
            />
          </div>
        ),
        label: "Announcements",
      });
    }
    if (checkRoutePermissions("fileManager")) {
      routes.push({
        key: "File Manager",
        icon: (
          <div className="sideBarMenu_itemImg_parent">
            {active === "File Manager" ? (
              <MdFilePresent color="white" size={21} />
            ) : (
              <MdFilePresent size={21} />
            )}
          </div>
        ),
        label: "File Manager",
      });
    }
    if (checkRoutePermissions("notificationPage")) {
      routes.push({
        key: "Notifications",
        icon: (
          <div className="sideBarMenu_itemImg_parent">
            <img
              src={active === "Notifications" ? Whitebell : Notification}
              alt="NT"
            />
          </div>
        ),
        label: (
          <>
            <span>Notifications</span>
            <Space size="middle">
              <Badge
                count={unreadCount > 0 ? unreadCount : 0}
                className="notifications-count"
              ></Badge>
            </Space>
          </>
        ),
      });
    }
    if (checkRoutePermissions("settingPage")) {
      routes.push({
        key: "Settings",
        icon: (
          <div className="sideBarMenu_itemImg_parent">
            <img src={active === "Settings" ? WhiteSetting : seting} alt="ST" />
          </div>
        ),
        label: "Settings",
      });
    }
    if (checkRoutePermissions("support")) {
      routes.push({
        key: "Support",
        icon: (
          <div className="sideBarMenu_itemImg_parent">
            <img src={active === "Support" ? WhiteMsg : msg} alt="SP" />
          </div>
        ),
        label: (
          <>
            <span>Supports</span>
            <Space size="middle">
              <Badge
                count={totalUnreadMessages}
                className="notifications-count"
              ></Badge>
            </Space>
          </>
        ),
      });
    }

    if (checkRoutePermissions("onboardingPage")) {
      routes.push({
        key: "Onboarding",
        icon: (
          <div className="sideBarMenu_itemImg_parent">
            <img
              src={active === "Onboarding" ? whiteOnboarding : onboard}
              alt="ON"
            />
          </div>
        ),
        label: "Onboarding",
      });
    }
    return routes;
  };

  const [active, setActive] = useState(getActiveKey);
  const [openModal, setOpenModal] = useState<any>(false);

  useEffect(() => {
    setActive(getActiveKey());
  }, [pathname, getActiveKey]);

  const navigate = useNavigate();

  const handleMenuClick = (event: { key: string }) => {
    visible && setVisible && setVisible(false);

    if (isActive) {
      const confirmNavigation = window.confirm(
        "you have unsaved progress are you sure you want to navigate"
      );
      if (!confirmNavigation) {
        return;
      }
      dispatch(setActiveProcess(false));
    }

    switch (event.key) {
      case "Dashboard":
        navigate("/");
        break;
      case "Classes":
        navigate("/classes");
        break;
      case "Class":
        navigate("/class");
        break;
      case "Targets":
        navigate("/targets");
        break;
      case "Curriculum":
        navigate("/curriculum");
        break;
      case "Subjects":
        navigate("/subjects");
        break;
      case "Students":
        navigate("/students");
        break;
      case "Teachers":
        navigate("/teachers");
        break;
      case "Reporting":
        navigate("/reporting");
        break;
      case "Monitoring":
        navigate("/monitoring");
        break;
      case "Schedule":
        navigate("/schedule");
        break;
      case "Assessments":
        navigate("/assessment");
        break;
      case "File Manager":
        navigate("/file-manager");
        break;
      case "Announcements":
        navigate("/announcement");
        break;
      case "Notifications":
        navigate("/notifications");
        break;
      case "Settings":
        navigate("/settings");
        break;
      case "Support":
        navigate("/support");
        break;
      case "Onboarding":
        navigate("/signuplink");
        break;
      case "Reports":
        navigate("/reports");
        break;
      case "Learning":
        navigate("/learning");
        break;
      case "Exams":
        navigate("/exams");
        break;
      case "Planner":
        navigate("/planner/details/student-details");
        break;
      case "Student Planner":
        navigate("/student/planner");
        break;
      case "Student Planner Details":
        navigate("/planner/details/student-details");
        break;
      default:
        navigate("/");
    }
  };

  const handleToggleCollapse = () => {
    visible && setVisible && setVisible(false);
    setCollapsed && setCollapsed(!collapsed);
  };

  return (
    <div className={collapsed ? "collapse-siderMenu" : "siderMenu"}>
      <LogoutModal
        //@ts-ignore
        openModal={openModal}
        setOpenModal={setOpenModal}
      />
      <div
        className="sider_top cursor-pointer"
        style={{ height: `${headerHeight}px` }}
      >
        <div
          style={{
            marginTop: "20px",
          }}
        >
          {collapsed ? (
            <img
              className="logo"
              src={closeLogo}
              style={{ width: "30px" }}
              alt="Logo"
            />
          ) : (
            <div className="newLogoDiv" style={{ display: "flex" }}>
              <img className="logo" src={closeLogo} alt="Logo" />

              <h3>Assabeel Tracker</h3>
            </div>
          )}
        </div>
        <div className={`card ${collapsed ? "collapsed-card" : ""}`}>
          {collapsed ? (
            <img
              alt=""
              src={user?.imageUrl || profile}
              style={{
                width: "41px",
                height: "44px",
                marginLeft: "3px",
                marginTop: "2px",
                borderRadius: "6px",
              }}
            />
          ) : (
            <>
              <div className="imgDiv">
                <img
                  src={user?.imageUrl || profile}
                  style={{
                    width: "40px",
                    borderRadius: "6px",
                    objectFit: "cover",
                  }}
                  alt="Avatar"
                />
              </div>
              <div className="detailBox">
                <h3 title={user?.firstName}>{user?.firstName}</h3>
                <p title={user?.email}>{user?.email}</p>
              </div>
              <div>
                <IoLogOutOutline size={24} onClick={() => setOpenModal(true)} />
              </div>
            </>
          )}
        </div>
      </div>

      <Menu
        mode="inline"
        selectedKeys={[active]}
        onClick={handleMenuClick}
        className="custom-scrollbar"
        style={{
          height: `calc(100vh - (${headerHeight + footerHeight}px))`,
          marginTop: "20px",
          overflowY: "scroll",
          backgroundColor: "black",
        }}
        items={getMenuItems()}
      />

      <Button
        className="openbtn"
        onClick={() => handleToggleCollapse()}
        style={{ marginLeft: collapsed ? "8px" : "85px" }}
      >
        {collapsed ? (
          <MdOutlineKeyboardDoubleArrowRight className="btnicon" />
        ) : (
          <MdOutlineKeyboardDoubleArrowLeft className="btnicon" />
        )}
      </Button>
    </div>
  );
};

export default SiderMenu;
