import Axios from "axios";

const configURLS = {
  production: {
    baseURL: "https://api.assabeel.app",
  },
  staging: {
    baseURL: "https://api.assabeel.app",
  },
  local: {
    baseURL: "http://192.168.100.99:9090",
  },
};

const environment = "staging"; // staging, production, local

export const getBaseUrl = () => {
  return configURLS[environment].baseURL;
};

export const publicAPI = Axios.create({
  baseURL: configURLS[environment].baseURL,
});

export const getToken = () => localStorage.getItem("token");

export const privateAPI = Axios.create({
  baseURL: configURLS[environment].baseURL,
  headers: {
    common: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  },
});

export const attachToken = async (token: string) => {
  const jwt = localStorage.getItem("token");
  privateAPI.defaults.headers.common.Authorization = `Bearer ${token || jwt}`;
};

export const isMobile = window.innerWidth < 767;
export const isTab = window.innerWidth <= 980;
