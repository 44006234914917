import React, { useEffect, useState } from "react";
import { Modal, Input, Button, Spin, notification, Tooltip } from "antd";

import { GoDotFill } from "react-icons/go";
import { FiPlus } from "react-icons/fi";
import { firstLetterCap } from "../../../utils";
import {
  createProgressNote,
  getProgressHistory,
} from "../../../store/services/class";
import moment from "moment";
import { IoTrashOutline } from "react-icons/io5";
import { RiAttachment2 } from "react-icons/ri";
import { createProgress , updateStudentLevel } from "../../../store/services/class";
import { setActiveProcess } from "../../../store/slices/Classes/activeSlice";
import { useAppDispatch } from "../../../store/hooks";
import { getStudentProgressByLevel } from "../../../store/services/class";
import { getAllStudentsByClassSubject } from "../../../store/services/student";
const { TextArea } = Input;

interface Props {
  open: boolean;
  close: () => void;
  data: any;
  onProgressAdded?: () => void;
  updateNote?: any;
}

const ProgressNoteModal: React.FC<Props> = ({
  data,
  open,
  close,
  onProgressAdded,
  updateNote
}) => {
  const [loading, setLoading] = useState<string>("");
  const [history, setHistory] = useState<Array<any>>([]);
  const [file, setFile] = useState<any>(null);
  const [note, setNote] = useState<any>();

  const dispatch = useAppDispatch();
  useEffect(() => {
    if (open) {
      getHistory();
    }
  }, [open]);

  const getHistory = async () => {
    setLoading("history");
    const res = await getProgressHistory({
      studentId: data?.studentId,
      levelId: data?.levelId,
      moduleId: data?.progress?.moduleId || data?.progress,
      lessonId: data?.lessonId,
    });
    if (res) {
      setHistory(res);
    }
    setLoading("");
  };

  const _getProgress = async (levelId: number, isSync?: boolean) => {
    await getStudentProgressByLevel({
      levelId,
      isSync: isSync ? true : false,
    });
  };

  const onFinish = async () => {
    if (note?.trim() === "") {
      notification.error({
        message:
          " Please make sure to fill out the note field before submitting",
      });
      return;
    }
    setLoading("note-btn");
    let res ;
    let payload ;
    if(data?.saveWithNote){
      // payload = {
      //   ...data?.progress,
      //   notes: note
      // };
      // console.log("🚀 ~ onFinish ~ payload:", payload)
      // res = await createProgress({ progress: [payload] });
      // if (res) {
      //   let classId= payload?.classId;
      //   let subjectId= payload?.subjectId;
  
      //   const levelRes = await updateStudentLevel({
      //     classId: classId,
      //     subjectId: subjectId,
      //   });
      //   if (levelRes) {
      //     await _getProgress(payload?.levelId , true)
      //     dispatch(setActiveProcess(false));
      //   }
      //   await getAllStudentsByClassSubject({
      //     classId: Number(classId),
      //     subjectId: subjectId,
      //   });
      // }
      updateNote({note, indexOfObject : data?.indexOfObject})
    }else{
      payload = {
        notes: note,
        progressId: data?.progress?.progressId,
      };

      const formData = new FormData();
      formData.append("notes", note);
      formData.append("pictures", file?.[0]);
      formData.append("progressId", data?.progress?.progressId);
      res = await createProgressNote(file ? formData : payload);
    }

    if (res) {
      handleCancel();
      if (onProgressAdded) {
        onProgressAdded();
      }
    }
    setLoading("");
    close();
    if (note?.trim() !== "") setNote("");
    if (history?.length > 0) setHistory([]);
    if (loading) setLoading("");
    if (file) setFile(null);
  };

  const handleCancel = () => {
    close();
    if (note?.trim() !== "") setNote("");
    if (history?.length > 0) setHistory([]);
    if (loading) setLoading("");
    if (file) setFile(null);
  };

  const truncateString = (inputString: string) => {
    const words = inputString?.split("");
    if (words?.length > 15) {
      const start = words.slice(0, 7).join("");
      const end = words.slice(-6).join("");
      return `${start} ... ${end}`;
    } else {
      return inputString;
    }
  };

  return (
    <Modal
      open={open}
      className="_progressNoteModal"
      onCancel={() => {
        handleCancel();
      }}
      footer={null}
      centered
    >
      <div className="_modalBody">
        <div className="_modalHeader">
          <div className="_imageBox">
            <img
              src={
                data?.imageUrl
                  ? data?.imageUrl
                  : require("../../../assets/user.png")
              }
              alt="Student"
            />
          </div>
          <div className="_content">
            <h2>{firstLetterCap(data?.studentName)}</h2>
            <div>
              <p>{data?.className}</p>
              <GoDotFill />
              <p>{data?.subjectName}</p>
            </div>
          </div>
        </div>
        <div className="_lessonBox">
          <p>{data?.lessonName}</p>
        </div>
        <div className="_textareaBox">
          <TextArea
            value={note}
            autoSize={{ minRows: 4, maxRows: 1000 }}
            onChange={(e) => setNote(e.target.value)}
            placeholder="Type note here..."
          />
        </div>

        <div>
          <label htmlFor="file-upload" className="file-label">
            {" "}
            <FiPlus
              color="#000"
              size={20}
              style={{ marginBottom: "-5px" }}
            />{" "}
            Attach file
          </label>
          <input
            type="file"
            id="file-upload"
            className="file-input"
            onChange={(e) => setFile(e.target.files)}
          />
        </div>
        {file && (
          <div className="_fileNameRow">
            <p>{truncateString(file?.[0]?.name)}</p>
            <IoTrashOutline
              style={{
                width: "20px",
                height: "20px",
                color: "#D92D20",
                cursor: "pointer",
              }}
              onClick={() => setFile(null)}
            />
          </div>
        )}
        {loading === "history" ? (
          <div className="_historyLoadingBox">
            <Spin />
          </div>
        ) : history?.length > 0 ? (
          <div className="_descriptionBox green-scrollbar">
            {history
              ?.slice()
              ?.sort(
                (a, b) =>
                  new Date(b?.createdDate).getTime() -
                  new Date(a?.createdDate).getTime()
              )
              ?.map((v, i) => {
                const getColor = (status: string) => {
                  const colors: any = {
                    pass: { bgColor: "green", textColor: "#fff", text: "Met" },
                    fail: {
                      bgColor: "red",
                      textColor: "#fff",
                      text: "Not Met",
                    },
                    "in-progress": {
                      bgColor: "orange",
                      textColor: "#000",
                      text: "In Progress",
                    },
                    default: { bgColor: "#fff", textColor: "#000", text: "" },
                  };

                  return colors[status] || colors.default;
                };

                return (
                  <div key={i} className="_descriptionCard">
                    <p>{moment(v?.createdDate).format("MMM DD, YYYY")}</p>
                    <div>
                      <Tooltip title={v?.notes || "-"}>
                        <h2>{v?.notes || "-"}</h2>
                      </Tooltip>
                      <div>
                        {!!v?.file && (
                          <RiAttachment2
                            size={20}
                            style={{ cursor: "pointer" }}
                            onClick={() => (window.location.href = v?.file)}
                          />
                        )}
                        <p
                          style={{
                            backgroundColor: getColor(v?.progress)?.bgColor,
                            color: getColor(v?.progress)?.textColor,
                          }}
                        >{`${getColor(v?.progress)?.text}`}</p>
                        <p>{`${v?.firstName} ${v?.lastName}`}</p>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        ) : (
          <div className="_historyEmptyBox">
            <p>No History found!</p>
          </div>
        )}
        <div className="_actionButton">
          <Button onClick={handleCancel} disabled={loading === "note-btn"}>
            Cancel
          </Button>
          <Button loading={loading === "note-btn"} onClick={onFinish}>
            Okay
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ProgressNoteModal;
