import "../../styles/pages/assesments.css";
import { useNavigate } from "react-router-dom";
import { Row, Spin, Table, Flex, Popconfirm } from "antd";
import GHeader from "../../components/GeneralHeader/GHeader";
import { checkRoutePermissions } from "../../utils/permission";
import NotAccess from "../NotAccess";
import { useAppSelector } from "../../store/hooks";
import { CiRead } from "react-icons/ci";
import { deleteExamById, getAllExams } from "../../store/services/assesment";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import CustomPagination from "../../components/Pagination/CustomPagination";
import { IoTrashOutline } from "react-icons/io5";

import { FaRegEdit } from "react-icons/fa";

const Assessment = () => {
  const navigate = useNavigate();
  const [exam, setExam] = useState<any>([]);
  const [counts, setCounts] = useState(0);
  const [load, setLoad] = useState(false);
  const [pages, setPages] = useState(1);

  const { examList } = useAppSelector((state) => state.exam);

  const deleteExam = async (id: any) => {
    setLoad(true);
    const res = await deleteExamById({ examId: id });
    if (res) fetchExam();
    setLoad(false);
  };

  const columns = [
    {
      title: "Class Name",
      dataIndex: "className",
      render: (text: string) => {
        return <p style={{ marginLeft: "20px" }}>{text}</p>;
      },
    },
    {
      title: "Exam Name",
      dataIndex: "examName",
      render: (text: string) => {
        return <p>{text}</p>;
      },
    },
    {
      title: "Assign Teacher",
      dataIndex: "firstName",
      render: (text: any, record: any) => {
        return (
          <p>
            {record.firstName}
            {"  "}
            {record.lastName}
          </p>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "submitted",
      render: (text: string) => {
        return <p>{!Boolean(text) ? "inProgress" : "completed"}</p>;
      },
    },
    {
      title: "Details",
      dataIndex: "id",
      render: (text: any, record: any) => {
        return (
          <div style={{ marginLeft: "20px", display: "flex", gap: "15px" }}>
            <FaRegEdit
              color="gray"
              fontSize={20}
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate("/create-assesment", {
                  state: {
                    edit: true,
                    data: record,
                  },
                });
              }}
            />
            <Link to={`/examDetails/${text}`}>
              <CiRead color="black" fontSize={20} />
            </Link>{" "}
            <Popconfirm
              title="Delete Assessment"
              description="Are you sure to delete Assessment ?"
              onConfirm={() => {
                deleteExam(text);
              }}
              okText="Yes"
              cancelText="No"
            >
              <IoTrashOutline
                style={{
                  width: "20px",
                  height: "20px",
                  color: "#D92D20",
                  cursor: "pointer",
                }}
              />
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  const createClass = () => {
    navigate("/create-assesment");
  };

  const fetchExam = async () => {
    setLoad(true);
    try {
      const res = await getAllExams({ page: pages, limit: 10 });
      setCounts(res.count);
      setLoad(false);
    } catch (error: any) {
      setLoad(false);
      console.log("error", error.message);
    }
  };
  const setCurrentPage = (e: any) => {
    setPages(e);
  };

  useEffect(() => {
    fetchExam();
  }, [pages]);

  return checkRoutePermissions("assessmentsPage") ? (
    <main
      style={{
        textAlign: "center",
      }}
    >
      <div className="mainDiv responsive">
        <GHeader
          heading="Assessments"
          onClick={createClass}
          buttonText="Create an Exam"
        />
        {load ? (
          <Flex
            align="center"
            justify="center"
            style={{ height: "50vh" }}
            gap="middle"
          >
            <Spin size="large" />
          </Flex>
        ) : (
          <>
            <Row
              justify={{ xs: "center" }}
              style={{
                padding: "20px",
                backgroundColor: "#FFFFFF",
                borderRadius: "10px",
                height: "60vh",
              }}
            >
              {/* <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}>
            <div>
              <img src={assessmentImage} alt="Upload assesment" />
              <h4>You have no exams here.</h4>
              <h4 className="create-exam-title">Create an exam.</h4>
              <Button
                onClick={() => navigate("/create-assesment")}
                icon={<FaPlus />}
                className="create-exam-btn"
              >
                Create an Exam
              </Button>
            </div>
          </div> */}

              <div className="table_container" style={{ width: "100%" }}>
                <Table
                  className="custom_table_design"
                  scroll={{ x: 600 }}
                  dataSource={examList}
                  columns={columns}
                  pagination={false}
                />
              </div>
            </Row>
            <div>
              <CustomPagination
                total={counts}
                currentPage={pages}
                setCurrentPage={setCurrentPage}
              />
            </div>
          </>
        )}
      </div>
    </main>
  ) : (
    <NotAccess />
  );
};

export default Assessment;
