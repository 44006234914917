import React, { useState } from "react";

// import "./styles.css";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { isEmpty } from "lodash";
import { Empty, Popconfirm, Spin } from "antd";
import { MdOutlineArchive } from "react-icons/md";

import { RiDraggable } from "react-icons/ri";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  width: "100%",
  position: "relative",
});

const DragAndDrop = ({
  items,
  setItems,
  loading,
  handleTargetClick,
  addArchiveLesson,
  handleDropEnd,
}) => {
  const [dragItemId, setDragItemId] = useState("");

  const queryAttr = "data-rbd-drag-handle-draggable-id";
  const [placeholderProps, setPlaceholderProps] = useState({});

  const handleDragStart = (event) => {
    // console.log("event?.draggableId", event?.draggableId);
    const draggedDOM = getDraggedDom(String(event.draggableId));
    setDragItemId(event?.draggableId);

    if (!draggedDOM) {
      return;
    }

    const { clientHeight, clientWidth } = draggedDOM;
    const sourceIndex = event.source.index;
    const clientY =
      parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingTop) +
      [...draggedDOM.parentNode.children]
        .slice(0, sourceIndex)
        .reduce((total, curr) => {
          const style = curr.currentStyle || window.getComputedStyle(curr);
          const marginBottom = parseFloat(style.marginBottom);
          return total + curr.clientHeight + marginBottom;
        }, 0);

    setPlaceholderProps({
      clientHeight,
      clientWidth,
      clientY,
      clientX: parseFloat(
        window.getComputedStyle(draggedDOM.parentNode).paddingLeft
      ),
    });
  };

  const handleDragEnd = (result) => {
    setPlaceholderProps({});
    if (!result.destination) {
      return;
    }

    const listItems = reorder(
      items,
      result.source.index,
      result.destination.index
    );

    // console.log("Yolooooo", {
    //   items,
    //   source: result.source.index,
    //   destination: result.destination.index,
    // });

    const sourceId = items[result.source.index].lessonId;
    const destinationId = items[result.destination.index].lessonId;

    // console.log("Yolooooo", {
    //   sourceId,
    //   destinationId,
    // });

    setItems(listItems);

    // handleDropEnd(listItems);

    setDragItemId("");
  };

  const handleDragUpdate = (event) => {
    if (!event.destination) {
      return;
    }

    const draggedDOM = getDraggedDom(String(event.draggableId));

    if (!draggedDOM) {
      return;
    }

    const { clientHeight, clientWidth } = draggedDOM;
    const destinationIndex = event.destination.index;
    const sourceIndex = event.source.index;

    const childrenArray = [...draggedDOM.parentNode.children];
    const movedItem = childrenArray[sourceIndex];
    childrenArray.splice(sourceIndex, 1);

    const updatedArray = [
      ...childrenArray.slice(0, destinationIndex),
      movedItem,
      ...childrenArray.slice(destinationIndex + 1),
    ];

    const clientY =
      parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingTop) +
      updatedArray.slice(0, destinationIndex).reduce((total, curr) => {
        const style = curr.currentStyle || window.getComputedStyle(curr);
        const marginBottom = parseFloat(style.marginBottom);
        return total + curr.clientHeight + marginBottom;
      }, 0);

    setPlaceholderProps({
      clientHeight,
      clientWidth,
      clientY,
      clientX: parseFloat(
        window.getComputedStyle(draggedDOM.parentNode).paddingLeft
      ),
    });
  };

  const getDraggedDom = (draggableId) => {
    const domQuery = `[${queryAttr}='${draggableId}']`;
    const draggedDOM = document.querySelector(domQuery);

    return draggedDOM;
  };

  // Normally you would want to split things out into separate components.
  // But in this example everything is just done in one place for simplicity
  return (
    <DragDropContext
      onDragEnd={handleDragEnd}
      onDragStart={handleDragStart}
      onDragUpdate={handleDragUpdate}
    >
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}
          >
            {loading ? (
              <div className="loadingBox">
                <Spin />
              </div>
            ) : items?.length > 0 ? (
              items.map((item, index) => (
                <Draggable
                  key={String(item.lessonId)}
                  draggableId={String(item.lessonId)}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                      className={`${
                        dragItemId == item.lessonId ? "activeDrag" : ""
                      }`}
                    >
                      <DndCard
                        item={item}
                        index={index}
                        handleTargetClick={handleTargetClick}
                        addArchiveLesson={addArchiveLesson}
                      />
                    </div>
                  )}
                </Draggable>
              ))
            ) : (
              <Empty
                style={{ marginBottom: "30px" }}
                description="No step here"
              />
            )}
            {provided.placeholder}
            {!isEmpty(placeholderProps) && snapshot.isDraggingOver && (
              <div
                className="placeholder"
                style={{
                  top: placeholderProps?.clientY,
                  //   left: placeholderProps.clientX,
                  right: 0,
                  height: placeholderProps.clientHeight - 30,
                  width: placeholderProps.clientWidth - 40,
                }}
              />
            )}
          </div>
        )}
      </Droppable>

      {/* {type === "funnel" && <Footer />} */}
    </DragDropContext>
  );
};
const DndCard = ({ item, index, handleTargetClick, addArchiveLesson }) => {
  return (
    <div
      className="dndItem"
      onClick={() =>
        item.status === "active" && handleTargetClick(item?.lessonId)
      }
    >
      <div className="dndContent">
        <RiDraggable size={20} />
        <span>{index + 1}</span>
        <p>{item?.lessonName}</p>
      </div>
      <Popconfirm
        title="Archive Lesson"
        description="Are you sure add to archive ?"
        onConfirm={(e) => {
          e?.stopPropagation();
          addArchiveLesson(item.lessonId);
        }}
        okText="Yes"
        cancelText="No"
      >
        <MdOutlineArchive
          className="lesson_archive_unarchive_btn"
          onClick={() => {}}
        />
      </Popconfirm>
    </div>
  );
};

export default DragAndDrop;
