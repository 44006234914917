export const firstLetterCap = (str: string) => {
  return str ? str[0]?.toUpperCase() + str.slice(1) : str;
};

export const dateFormat = "YYYY-MM-DD";

export const isDarkColor = (color: any) => {
  const [r, g, b] = color?.match(/\d+/g)?.map(Number) || [255, 255, 255];
  const luminance = 0.299 * (r / 255) + 0.587 * (g / 255) + 0.114 * (b / 255);
  return luminance < 0.5;
};

export const getAward = (totalMarks: any, obtainedMarks: any) => {
  if (!totalMarks && !obtainedMarks) return;

  let type = "";
  let bgColor = "";
  let textColor = "";

  const percentage = totalMarks > 0 ? (obtainedMarks / totalMarks) * 100 : 0;

  if (percentage >= 85) {
    type = "Gold Award";
    // bgColor = "#C9B037";
    bgColor =
      "linear-gradient(0deg, rgba(175,149,0,1) 0%, rgba(201,176,55,1) 100%)";
    textColor = "black";
  } else if (percentage >= 70) {
    type = "Silver Award";
    // bgColor = "#B4B4B4";
    bgColor =
      "linear-gradient(0deg, rgba(180,180,180,1) 0%, rgba(215,215,215,1) 100%)";
    textColor = "black";
  } else if (percentage >= 55) {
    type = "Bronze Award";
    // bgColor = "#AD8A56";
    bgColor =
      "linear-gradient(0deg, rgba(106,56,5,1) 0%, rgba(173,138,86,1) 100%)";
    textColor = "white";
  }

  return { type, bgColor, textColor };
};

export const sortSubject = (arr: any) => {
  if (arr.length < 0) return;
  return arr?.slice()?.sort((a: any, b: any) => a?.sortNumber - b?.sortNumber);
};
export const sortLessonByOrder = (arr: any) => {
  if (arr.length < 0) return;
  return arr?.slice()?.sort((a: any, b: any) => a?.orderNo - b?.orderNo);
};
