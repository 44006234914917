import React, { useEffect, useRef, useState } from "react";
import { Button, Form, Input, InputRef, Modal, notification , Upload} from "antd";
import { IoIosAdd } from "react-icons/io";
import { MdDeleteOutline } from "react-icons/md";
import { createTarget, updateTarget } from "../../../store/services/curriculum";
import { UploadOutlined } from "@ant-design/icons";
import { any } from "lodash/fp";

const { TextArea } = Input;

interface ClassModalProps {
  setIsModalOpens: (isOpen: boolean) => void;
  isModalOpens: boolean;
  currentModule: string;
  bool?: boolean;
  getData?: () => void;
  editMode?: boolean;
  lessonData?: any;
  callGetData?: () => void;
  isTeacher?: boolean;
}

interface MaterialAdd {
  material?: string;
  plan?: string;
  resource?: string;
  link?: string;
  lessonId: string;
}

type MaterialType = string | MaterialAdd;

const TargetModel: React.FC<ClassModalProps> = ({
  setIsModalOpens,
  isModalOpens,
  currentModule,
  getData,
  editMode,
  lessonData,
  callGetData,
  isTeacher,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [material, setMaterials] = useState<MaterialType[]>([]);
  const [mater, setMater] = useState<string>("");
  const [plans, setPlans] = useState<MaterialType[]>([]);
  const [resources, setResources] = useState<MaterialType[]>([]);
  const [links, setLinks] = useState<MaterialType[]>([]);
  const materialRef = useRef<InputRef>(null);
  const planRef = useRef<InputRef>(null);
  const resourcesRef = useRef<InputRef>(null);
  const linkRef = useRef<InputRef>(null);
  const [selectedItemsForDelete, setSelectedItemsForDelete] = useState<any>({
    materials: [],
    plans: [],
    links: [],
    resources: [],
  });

  const handleCancel = () => {
    setIsModalOpens(false);
    if (!editMode) {
      form.resetFields();
      setPlans([]);
      setResources([]);
      setLinks([]);
      setMaterials([]);
    }
  };

  const sendDAta = async () => {
    try {
      let values = await form.validateFields();
      // if (
      //   material.length === 0 ||
      //   plans.length === 0 ||
      //   resources.length === 0 ||
      //   links.length === 0
      // ) {
      //   notification.error({
      //     message: "You have to filled all the form to prceed further",
      //   });
      //   return;
      // }
      setLoading(true);
      const data = {
        lessonName: values.lessonName || lessonData.lessonName,
        moduleId: currentModule,
        duration: Number(values.duration) || lessonData.duration,
        objective: values.objective || lessonData.objective,
        longTermAim: values.longTermAim || lessonData.longTermAim,
        unit: "min",
        materials: material,
        plans: plans,
        resources: resources,
        links: links,
        pictures: values.files.file
      };

      let res = null;
      if (editMode) {
        res = await updateTarget({
          ...data,
          lessonId: lessonData.lessonId,
          materials: [...data.materials, ...selectedItemsForDelete.materials],
          plans: [...data.plans, ...selectedItemsForDelete.plans],
          resources: [...data.resources, ...selectedItemsForDelete.resources],
          links: [...data.links, ...selectedItemsForDelete.links],
        });
      } else {
        console.log("🚀 ~ //Object.keys ~ data:", data)
        const formData = new FormData();     
        Object.keys(data).forEach((key) => {
          const value = (data as Record<string, any>)[key];
        
          if (Array.isArray(value)) {
            // Append each item in the array with the same key (Backend should handle it as an array)
            value.forEach((item, index) => {
              formData.append(`${key}[]`, item);
            });
          } else {
            formData.append(key, value);
          }
        });
        // Object.keys(data).forEach((key) => {
        //   formData.append(key, (data as Record<string, any>)[key]);
        // });


        res = await createTarget(formData);
      }

      if (res && !editMode) {
        form.resetFields();
        notification.success({ message: "Target created successfully!" });
        handleCancel();
        getData && (await getData());
      } else if (res) {
        // form.resetFields();
        setSelectedItemsForDelete({
          materials: [],
          plans: [],
          resources: [],
          links: [],
        });
        notification.success({ message: "Target updated successfully!" });
        // handleCancel();
        callGetData && (await callGetData());
      } else {
        if (!editMode)
          notification.error({ message: "Failed to create target." });
        else notification.error({ message: "Failed to update target." });
      }
    } catch (error) {
      console.error("Error:", error);
      notification.error({
        message: "An error occurred during the creation process.",
      });
    } finally {
      setLoading(false);
    }
  };

  const addMaterial = () => {
    if (materialRef.current && materialRef.current.input) {
      if (editMode) {
        setMaterials([
          ...material,
          {
            material: materialRef.current.input.value,
            lessonId: lessonData.lessonId,
          },
        ]);
      } else {
        setMaterials([...material, materialRef.current.input.value]);
      }
      materialRef.current.input.value = "";
    }
  };

  const addPlans = () => {
    if (planRef.current && planRef.current.input) {
      if (editMode) {
        setPlans([
          ...plans,
          {
            plan: planRef.current.input.value,
            lessonId: lessonData.lessonId,
          },
        ]);
      } else {
        setPlans([...plans, planRef.current.input.value]);
      }
      planRef.current.input.value = "";
    }
  };

  const addResources = () => {
    if (resourcesRef.current && resourcesRef.current.input) {
      if (editMode) {
        setResources([
          ...resources,
          {
            resource: resourcesRef.current.input.value,
            lessonId: lessonData.lessonId,
          },
        ]);
      } else {
        setResources([...resources, resourcesRef.current.input.value]);
      }
      resourcesRef.current.input.value = ""; // Clear input value
    }
  };

  const AddLink = () => {
    if (linkRef.current && linkRef.current.input) {
      if (editMode) {
        setLinks([
          ...links,
          { link: linkRef.current.input.value, lessonId: lessonData.lessonId },
        ]);
      } else {
        setLinks([...links, linkRef.current.input.value]);
      }
      linkRef.current.input.value = ""; // Clear input value
    }
  };

  const removeMaterialData = (index: number, item: any) => {
    const updatedMaterials = [
      ...material.slice(0, index),
      ...material.slice(index + 1),
    ];
    if (editMode) {
      setSelectedItemsForDelete({
        ...selectedItemsForDelete,
        materials: [
          ...selectedItemsForDelete.materials,
          { ...item, delete: true },
        ],
      });
    }
    setMaterials(updatedMaterials);
  };
  const removePlans = (index: number, item: any) => {
    const updatedMaterials = [
      ...plans.slice(0, index),
      ...plans.slice(index + 1),
    ];
    if (editMode) {
      setSelectedItemsForDelete({
        ...selectedItemsForDelete,
        plans: [...selectedItemsForDelete.plans, { ...item, delete: true }],
      });
    }
    setPlans(updatedMaterials);
  };
  const removeResources = (index: number, item: any) => {
    const updatedMaterials = [
      ...resources.slice(0, index),
      ...resources.slice(index + 1),
    ];
    if (editMode) {
      setSelectedItemsForDelete({
        ...selectedItemsForDelete,
        resources: [
          ...selectedItemsForDelete.resources,
          { ...item, delete: true },
        ],
      });
    }
    setResources(updatedMaterials);
  };
  const removeLinks = (index: number, item: any) => {
    const updatedMaterials = [
      ...links.slice(0, index),
      ...links.slice(index + 1),
    ];
    if (editMode) {
      setSelectedItemsForDelete({
        ...selectedItemsForDelete,
        links: [...selectedItemsForDelete.links, { ...item, delete: true }],
      });
    }
    setLinks(updatedMaterials);
  };

  useEffect(() => {
    console.log('- - - - -',form.getFieldsValue())  
  },[form])

  useEffect(() => {
    if (editMode && lessonData) {
      form.setFieldsValue({
        lessonName: lessonData.lessonName,
        duration: lessonData.duration,
        objective: lessonData.objective,
        longTermAim: lessonData.longTermAim,
      });
      setMaterials(lessonData.materials || []);
      setPlans(lessonData.plans || []);
      setResources(lessonData.resources || []);
      setLinks(lessonData.links || []);
    }
  }, [lessonData, editMode]);

  return (
    <>
      <Modal
        title={
          <div
            style={{
              width: "40px",
              height: "40px",
              display: "grid",
              placeItems: "center",
              borderRadius: "50%",
              backgroundColor: "#EFF8FF",
            }}
          >
            <IoIosAdd fontSize={"26px"} color="blue" />
          </div>
        }
        open={isModalOpens}
        onCancel={handleCancel}
        className="target"
        footer={null}
        centered
      >
        <div>
          <h2
            style={{
              fontSize: "18px",
              color: "#26252A",
              fontWeight: "600",
              fontFamily: "Plus Jakarta Sans",
            }}
          >
            {editMode ? "Update a Target" : "Add a Target"}
          </h2>
          <p
            style={{
              color: "#667085",
              fontSize: "13px",
              fontWeight: "400",
              fontFamily: "Plus Jakarta Sans",
            }}
          >
            Review and manage the targets.
          </p>
          <div style={{ marginTop: "20px" }}>
            <Form form={form} layout="vertical">
              {!isTeacher && (
                <>
                  <Form.Item
                    name="lessonName"
                    label="Lesson Name"
                    rules={[
                      { required: true, message: "Lesson name is required" },
                    ]}
                  >
                    <Input placeholder="Lesson Name" />
                  </Form.Item>
                  <Form.Item
                    name="duration"
                    label="Lessons Duration"
                    rules={[
                      { required: true, message: "Time duration is required" },
                    ]}
                  >
                    <Input
                      type="number"
                      placeholder="Number of lessons"
                    />
                  </Form.Item>
                  <Form.Item name="objective" label="Objective">
                    <Input placeholder="Objective" />
                  </Form.Item>
                  <Form.Item name="longTermAim" label="Long Term Aim">
                    <TextArea placeholder="Long Term Aim" />
                  </Form.Item>
                </>
              )}
              <Form.Item
                name="material"
                label="Materials"
                // rules={[
                //   {
                //     required: !editMode && !material.length,
                //     message: "Material is required",
                //   },
                // ]}
              >
                <Input
                  value={mater}
                  ref={materialRef}
                  // onChange={(e) => setMater(e.target.value)}
                  placeholder="Material"
                />
              </Form.Item>
              <Button
                onClick={addMaterial}
                style={{
                  backgroundColor: "#26252A",
                  color: "white",
                  marginTop: "10px",
                }}
              >
                Add
              </Button>

              <ul style={{ marginTop: "10px", marginBottom: "10px" }}>
                {material.length > 0 &&
                  material.map((item: any, index) => (
                    <div className="points-row" key={index}>
                      <li>{typeof item === "string" ? item : item.material}</li>
                      <MdDeleteOutline
                        onClick={() => {
                          removeMaterialData(index, item);
                        }}
                        fontSize={"18px"}
                        cursor={"pointer"}
                        color="red"
                      />
                    </div>
                  ))}
              </ul>
              <Form.Item name="plans" label="Plans">
                <Input ref={planRef} placeholder="Plans" />
              </Form.Item>
              <Button
                onClick={addPlans}
                style={{
                  backgroundColor: "#26252A",
                  color: "white",
                  marginTop: "10px",
                }}
              >
                Add
              </Button>
              <ul style={{ marginTop: "10px", marginBottom: "10px" }}>
                {plans.length > 0 &&
                  plans.map((item: any, index) => {
                    return (
                      <div className="points-row" key={index}>
                        <li>{typeof item === "string" ? item : item.plan}</li>
                        <MdDeleteOutline
                          onClick={() => {
                            removePlans(index, item);
                          }}
                          fontSize={"18px"}
                          cursor={"pointer"}
                          color="red"
                        />
                      </div>
                    );
                  })}
              </ul>
              <Form.Item name="files" label="Attached files">
                <Upload  beforeUpload={() => false}>
                  <Button icon={<UploadOutlined />}>Select File</Button>
                </Upload>
              </Form.Item>
              <Form.Item name="resources" label="Resources">
                <Input ref={resourcesRef} placeholder="Resources" />
              </Form.Item>
              <Button
                onClick={addResources}
                style={{
                  backgroundColor: "#26252A",
                  color: "white",
                  marginTop: "10px",
                }}
              >
                Add
              </Button>
              <ul style={{ marginTop: "10px", marginBottom: "10px" }}>
                {resources.length > 0 &&
                  resources.map((item: any, index) => (
                    <div className="points-row" key={index}>
                      <li>{typeof item === "string" ? item : item.resource}</li>
                      <MdDeleteOutline
                        onClick={() => {
                          removeResources(index, item);
                        }}
                        fontSize={"18px"}
                        cursor={"pointer"}
                        color="red"
                      />
                    </div>
                  ))}
              </ul>
              <Form.Item name="links" label="Links">
                <Input ref={linkRef} placeholder="Links" />
              </Form.Item>
              <Button
                onClick={AddLink}
                style={{
                  backgroundColor: "#26252A",
                  color: "white",
                  marginTop: "10px",
                }}
              >
                Add
              </Button>
              <ul style={{ marginTop: "10px", marginBottom: "10px" }}>
                {links.length > 0 &&
                  links.map((item: any, index) => (
                    <div className="points-row" key={index}>
                      <li>{typeof item === "string" ? item : item.link}</li>
                      <MdDeleteOutline
                        onClick={() => {
                          removeLinks(index, item);
                        }}
                        fontSize={"18px"}
                        cursor={"pointer"}
                        color="red"
                      />
                    </div>
                  ))}
              </ul>
            </Form>
          </div>
          <div
            style={{
              display: "flex",
              gap: "15px",
              marginTop: "10px",
              justifyContent: "flex-end",
            }}
          >
            <Button
              style={{ width: "120px", color: "#26252A" }}
              onClick={handleCancel}
              disabled={loading}
            >
              Cancel
            </Button>
            <Button
              loading={loading}
              disabled={loading}
              style={{
                width: "120px",
                backgroundColor: "#26252A",
                color: "white",
              }}
              onClick={sendDAta}
            >
              {editMode ? "Update" : "create"}
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default TargetModel;
