"use client";

import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Input,
  Row,
  Spin,
  Upload,
  Modal,
  Form,
  Popover,
} from "antd";
import GHeader from "../../components/GeneralHeader/GHeader";
import { CiSearch } from "react-icons/ci";
import { LuUpload } from "react-icons/lu";
import { InboxOutlined } from "@ant-design/icons";
import {
  uploadFiles,
  getFiles,
  deleteFileFrom,
} from "../../store/services/filemanager";
import imgIcon from "../../assets/defaultFile.png";
import pdfIcon from "../../assets/pdf_icon.png";
import moment from "moment";
import { SlOptionsVertical } from "react-icons/sl";
import { RiDeleteBinLine } from "react-icons/ri";
import { PiDownloadSimple } from "react-icons/pi";
import { useAppSelector } from "../../store/hooks";

import { FaRegEye } from "react-icons/fa";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import "@cyntler/react-doc-viewer/dist/index.css";
import { firstLetterCap } from "../../utils";

import { FaRegFileImage } from "react-icons/fa";
import { FaRegFileVideo } from "react-icons/fa";
import { IoDocumentOutline } from "react-icons/io5";
import { FaRegFileAlt } from "react-icons/fa";

interface FormValues {
  name: string;
  file: any;
}

const docType = [
  "pdf",
  "doc",
  "docx",
  "txt",
  "xls",
  "xlsx",
  "ppt",
  "pptx",
  "csv",
  "tsv",
];
const imgType = [
  "jpg",
  "jpeg",
  "png",
  "gif",
  "bmp",
  "svg",
  "webp",
  "tiff",
  "jfif",
];
const videoType = [
  "mp4",
  "mkv",
  "avi",
  "mov",
  "wmv",
  "flv",
  "webm",
  "mpeg",
  "3gp",
];

const FileManager = () => {
  const { user } = useAppSelector((state) => state.auth);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [allFiles, setAllFiles] = useState([]);
  const [refatch, setRefatch] = useState(false);
  const [disable, setDisable] = useState(false);
  const [form] = Form.useForm<FormValues>();
  const { Dragger } = Upload;
  const [open, setOpen] = useState(false);
  const [preview, setPreview] = useState<any>(null);
  console.log("🚀 ~ FileManager ~ preview:", preview);
  const _isAdmin = user?.role === "admin" ? true : false;

  const handleOpenChange = (newOpen: any) => {
    setOpen(open === newOpen ? false : newOpen);
  };
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onFinish = async (values: FormValues) => {
    console.log("🚀 ~ onFinish ~ values:", values);
    setIsLoading(true);
    const formData = new FormData();
    formData.append("name", values.name);
    formData.append("pictures", values.file.file);
    let response = await uploadFiles(formData);

    if (response) {
      setIsModalOpen(false);
      form.resetFields();
      setRefatch(!refatch);
      setDisable(false);
    }
    setIsLoading(false);
  };
  const fetchFiles = async () => {
    try {
      let response = await getFiles(search);
      setAllFiles(response.data);
    } catch (e) {
      console.log("🚀 ~ fetchFiles ~ e:", e);
    }
  };
  const DeleteFile = async (id: string) => {
    try {
      let response = await deleteFileFrom({ id });
      setRefatch(!refatch);
    } catch (e) {
      console.log("🚀 ~ fetchFiles ~ e:", e);
    }
  };

  useEffect(() => {
    fetchFiles();
  }, [refatch, search]);

  const setFileIconByType = (url: string) => {
    let fileChunks = url.split(".");
    let fileType = fileChunks[fileChunks.length - 1];

    let icon: any = <IoDocumentOutline className="svgIcon" color="red" />;

    if (docType.includes(fileType)) {
      icon = <FaRegFileAlt className="svgIcon" color="blue" />;
    } else if (imgType.includes(fileType)) {
      icon = <FaRegFileImage className="svgIcon" color="green" />;
    } else if (videoType.includes(fileType)) {
      icon = <FaRegFileVideo className="svgIcon" color="orange" />;
    } else {
      icon = <IoDocumentOutline className="svgIcon" color="red" />;
    }
    return icon;
  };

  const PopOverContent = (item: any) => {
    return (
      <div className="side_options_popover">
        <a
          href={item?.filelink}
          target="_blank"
          className="side_options_popover_option"
        >
          <PiDownloadSimple size={24} /> <div>download file</div>
        </a>
        {_isAdmin ? (
          <div
            className="side_options_popover_option delete_clr"
            onClick={() => DeleteFile(item?.fileId)}
          >
            <RiDeleteBinLine size={24} color="red" /> <div>delete file</div>
          </div>
        ) : (
          false
        )}
      </div>
    );
  };

  const handleOpenPreview = (data: any) => {
    setPreview({
      uri: data?.filelink,
      fileType: checkFileType(data?.filelink),
      fileName: data?.filename,
    });
  };
  const handleClosePreview = () => {
    setPreview(null);
  };

  const checkFileType = (url: any) => {
    let fileChunks = url.split(".");
    let fileType = fileChunks[fileChunks.length - 1];
    return fileType;
  };

  return (
    <>
      <div className="mainDiv responsive">
        <GHeader
          heading="File Manager"
          headingText="Upload files, pdf, videos, pictures etc."
        />
        <div className="fliters_container">
          <Row gutter={[20, 20]} className="file_manager_header">
            <Col xs={24} lg={_isAdmin ? 20 : 24}>
              <Input
                placeholder="Search..."
                onChange={(e) => setSearch(e.target.value)}
                prefix={
                  <CiSearch
                    style={{ width: "20px", height: "20px", color: "#667085" }}
                  />
                }
                className="filter_search"
              />
            </Col>
            {_isAdmin ? (
              <Col xs={24} sm={12} lg={4}>
                <div className="fm_upload_btn">
                  <button
                    className="openbtn"
                    style={{ border: 0, background: "none" }}
                    type="button"
                    onClick={showModal}
                  >
                    <LuUpload color="#000" size={20} /> Upload New
                  </button>
                </div>
              </Col>
            ) : (
              false
            )}
          </Row>
        </div>
        <div className="main" style={{ height: "auto" }}>
          <Row className="file_grid">
            {allFiles?.length ? (
              allFiles.map((item?: any, ind?: number) => {
                return (
                  <Col xs={12} sm={8} lg={4} key={ind} className="file_box">
                    <div className="file_box_header">
                      {/* <Image
                        src={setFileIconByType(item?.filelink)}
                        alt="file"
                        height={30}
                        width={30}
                      /> */}
                      {setFileIconByType(item?.filelink)}
                      <p>{item?.filename}</p>
                    </div>
                    <div className="file_box_body">
                      <div
                        className="previewBox"
                        onClick={() => handleOpenPreview(item)}
                      >
                        <FaRegEye /> <p>Preview</p>
                      </div>
                      {setFileIconByType(item?.filelink)}
                      {/* <img
                        src={setFileIconByType(item?.filelink)}
                        alt="file"
                        height={100}
                        width={100}
                      /> */}
                    </div>
                    <div className="file_box_footer">
                      <div>
                        {moment(item?.uploadedAt).format("MMMM DD, YYYY")}
                      </div>
                      <Popover
                        content={() => PopOverContent(item)}
                        title="Action"
                        trigger="click"
                        open={open === item?.fileId}
                        onOpenChange={() => handleOpenChange(item?.fileId)}
                        className="side_options"
                      >
                        <SlOptionsVertical size={16} />
                      </Popover>
                    </div>
                  </Col>
                );
              })
            ) : (
              <Col
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  height: 300,
                }}
              >
                <div className="_isLoading">
                  <Spin />
                </div>
              </Col>
            )}
          </Row>
        </div>
      </div>

      <Modal
        title="Upload files."
        open={isModalOpen}
        onCancel={handleCancel}
        footer={false}
        className="file_manager_modal"
      >
        <Form
          form={form}
          name="validateOnly"
          layout="vertical"
          autoComplete="off"
          onFinish={onFinish}
        >
          <Form.Item
            name="name"
            label="file Name"
            className="labels"
            rules={[{ required: true, message: "Please input the file name!" }]}
          >
            <Input placeholder="Enter Name" className="labelinput" />
          </Form.Item>
          <Form.Item
            name="file"
            label="File"
            className="labels"
            rules={[{ required: true, message: "Please upload the file!" }]}
          >
            <Dragger
              name="file"
              className="file_modal_upload"
              beforeUpload={() => false}
              multiple={false}
              disabled={disable}
              onChange={(info) =>
                info.file ? setDisable(true) : setDisable(false)
              }
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Click or drag file to this area to upload
              </p>
              <p className="ant-upload-hint">Support for a single upload.</p>
            </Dragger>
          </Form.Item>
          <div className="modal_footer">
            <Button
              className="cancelbtn modal_footer_btn"
              onClick={handleCancel}
              disabled={isLoading}
            >
              Cancel
            </Button>
            <Button
              loading={isLoading}
              disabled={isLoading}
              htmlType="submit"
              className="addbtn modal_footer_btn"
            >
              Upload
            </Button>
          </div>
        </Form>
      </Modal>

      {!!preview && (
        <Modal
          title={`Preview - ${firstLetterCap(preview?.fileName)}`}
          open={!!preview}
          onCancel={handleClosePreview}
          footer={false}
          className="previewModal"
          width={1000}
          centered
        >
          {docType.includes(preview?.fileType) && (
            <DocViewer
              documents={[preview]}
              config={{ header: { disableHeader: true } }}
              pluginRenderers={DocViewerRenderers}
              style={{ height: "100%" }}
            />
          )}
          {imgType.includes(preview?.fileType) && (
            <div className="imagePreview">
              <img src={preview?.uri} alt={preview?.fileName} />
            </div>
          )}
          {videoType.includes(preview?.fileType) && (
            <div className="videoPreview">
              <video controls autoPlay>
                <source src={preview?.uri} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          )}
        </Modal>
      )}
    </>
  );
};

export default FileManager;
