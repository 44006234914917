import { useState } from "react";
import useWindowDimensions from "../../../Hook/useWindowDimensions";
import { checkRoutePermissions } from "../../../utils/permission";
import NotAccess from "../../NotAccess";
import DesktopView from "../ClassesProgress/DesktopView";
import MobileView from "./MobileView";
import { FiMinusCircle, FiPlusCircle } from "react-icons/fi";

const ClassesProgress = () => {
  const { windowWidth } = useWindowDimensions();
  const [zoom, setZoom] = useState(1);

  const handleZoomIn = () => {
    let newZoom = zoom + 0.1;
    newZoom = Math.round(newZoom * 10) / 10;
    if (newZoom <= 1) {
      setZoom(newZoom);
    }
  };

  const handleZoomOut = () => {
    let newZoom = zoom - 0.1;
    newZoom = Math.round(newZoom * 10) / 10;
    if (newZoom >= 0.5) {
      setZoom(newZoom);
    }
  };

  return checkRoutePermissions("classesProgressPage") ? (
    <>
      <div className="zoomInOutBox">
        <FiMinusCircle
          size={20}
          onClick={handleZoomOut}
          style={{ cursor: zoom === 0.5 ? "no-drop" : "pointer" }}
        />
        <FiPlusCircle
          size={20}
          onClick={handleZoomIn}
          style={{ cursor: zoom === 1 ? "no-drop" : "pointer" }}
        />
      </div>
      {windowWidth > 767 ? <DesktopView zoom={zoom} /> : <MobileView />}
    </>
  ) : (
    <NotAccess />
  );
};

export default ClassesProgress;
