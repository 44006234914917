import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Input,
  Progress,
  Select,
  Table,
  Modal,
  Space,
  Flex,
  Spin,
  message,
} from "antd";
import "../styles/pages/monitoring.css";

import { CiSearch } from "react-icons/ci";
import { RiExpandUpDownLine } from "react-icons/ri";
import { IoTrashOutline } from "react-icons/io5";
import CustomPagination from "../components/Pagination/CustomPagination";
import GHeader from "../components/GeneralHeader/GHeader";

import addstudent from "../assets/addstudent.png";

import NotAccess from "./NotAccess";
import { checkRoutePermissions } from "../utils/permission";
import {
  addMonitoringstudent,
  deleteMonitoring,
  getAllMonitoring,
} from "../store/services/monitoring";
import { getAllClasses } from "../store/services/teacher";
import { debounce } from "lodash";
import { getSubjectListByClassDropdown } from "../store/services/subject";
import Loading from "../components/Loading/Loading";
import { useNavigate } from "react-router-dom";
import {
  getAllStudentsByClass,
  getSubjectsStudent,
} from "../store/services/student";
import moment from "moment";
const Monitoring = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>([]);
  const [value, setValue] = useState(null);
  const [searchTerm, setSearchTerm] = useState<string | null>(null);
  const [classes, setClasses] = useState([]);
  const [subjectValue, setSubjectValue] = useState(null);
  const [dropdownSubjects, setDropdownSubjects] = useState<any>([]);
  const [selectedStudentForDelete, setSelectedStudentForDelete] = useState<{
    studentData: any;
    showConfirmationModal: boolean;
  }>({
    studentData: null,
    showConfirmationModal: false,
  });

  const [newLoading, setNewLoading] = useState("");

  const [newClassData, setNewClassData] = useState([]);
  const [newStudentData, setNewStudentData] = useState([]);
  const [newSubjectData, setNewSubjectData] = useState([]);

  const [newClassValue, setNewClassValue] = useState(undefined);
  const [newStudentValue, setNewStudentValue] = useState(undefined);
  const [newSubjectValue, setNewSubjectValue] = useState(undefined);

  // const [fetchClasses, setFetchClasses]=useState

  const [classDropdownLoad, setClassDropdownLoad] = useState<boolean>(false);

  const [pages] = useState(1);

  const navigate = useNavigate();

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    reset();
  };

  const columns = [
    {
      title: "First Name",
      dataIndex: "firstName",
      key: "firstName",
      width: "22%",
      render: (text: any, record: any) => {
        return (
          <div
            className="tab_left"
            style={{ cursor: "pointer" }}
            onClick={() => {
              navigate("/student-report", {
                state: { id: record?.studentId },
              });
            }}
          >{`${text}`}</div>
        );
      },
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      key: "lastName",
      width: "22%",
      render: (text: any) => {
        return <div className="tab_left">{text}</div>;
      },
    },
    {
      title: "Class",
      dataIndex: "className",
      key: "className",
      width: "22%",
      render: (text: any) => {
        return <div className="tabs_le">{text}</div>;
      },
    },
    {
      title: "Subjects",
      dataIndex: "subjectName",
      key: "subjectName",
      width: "18%",
      render: (text: any, record: any) => {
        return <div className="tabs_le">{text}</div>;
      },
    },
    {
      title: "Start Monitoring",
      dataIndex: "createdDate",
      key: "createdDate",
      width: "18%",
      render: (text: any, record: any) => {
        return <div className="tabs_le">{moment(text).format("YYYY-MM-D")}</div>;
      },
    },
    {
      title: "Current Progress",
      dataIndex: "percentage",
      key: "percentage",
      width: "22%",
      render: (per: any) => {
        return (
          <div>
            <Progress
              percent={Number(Number(per).toFixed(0))}
              size="small"
              className="small-progress"
            />
          </div>
        );
      },
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      width: "10%",
      render: (_: any, record: any) => {
        return (
          <div
            className="tabs_left"
            style={{ display: "flex", alignItems: "center", columnGap: "1rem" }}
          >
            <IoTrashOutline
              onClick={() =>
                setSelectedStudentForDelete({
                  studentData: record,
                  showConfirmationModal: true,
                })
              }
              style={{
                width: "20px",
                height: "20px",
                color: "#D92D20",
                cursor: "pointer",
              }}
            />
          </div>
        );
      },
    },
  ];

  const getAllMonitoringData = async () => {
    try {
      setLoading(true);
      const res = await getAllMonitoring({ page: currentPage, perPage: 10 });
      setData(res?.monitoringList);
      setCount(res?.count);
      setLoading(false);
    } catch (error) {
      console.log("error getAllMonitoringData", error);
      setLoading(false);
    }
  };

  const deleteMonitoringData = async (id: number) => {
    try {
      setLoading(true);
      const res = await deleteMonitoring({ monitoringId: id });
      if (res) {
        getAllMonitoringData();
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log("error", error);
    }
  };

  const changeValue = async () => {
    try {
      setLoading(true);
      const data = {
        searchTerm: searchTerm,
        page: pages,
        perPage: 10,
        classId: value,
        subjectId: subjectValue,
      };

      const res = await getAllMonitoring(data);
      setData(res?.monitoringList);
      setCount(res?.count);
      setLoading(false);
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };

  const fetchClasses = async () => {
    try {
      setClassDropdownLoad(true);
      const res = await getAllClasses({ status: "active", withData: false });
      setClasses(res?.data.records);
      setClassDropdownLoad(false);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getAllMonitoringData();
  }, [currentPage]);

  const submitData = async () => {
    if (!newClassValue) {
      message.error("Please select class");
      return;
    }
    if (!newStudentValue) {
      message.error("Please select student");
      return;
    }
    if (!newSubjectValue) {
      message.error("Please select subject");
      return;
    }
    setLoading(true);
    try {
      const payload = {
        studentId: newStudentValue,
        subjectId: newSubjectValue,
      };
      const res = await addMonitoringstudent(payload);
      if (res?.status === 200) {
        setIsModalOpen(false);
        reset();
        getAllMonitoringData();
      }
    } catch (error) {
      console.log("error", error);
      message.error("Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  const debounceFetchByClass = useCallback(debounce(changeValue, 1000), [
    searchTerm,
    subjectValue,
  ]);

  useEffect(() => {
    if (searchTerm || subjectValue) {
      debounceFetchByClass();
      return () => {
        debounceFetchByClass.cancel();
      };
    } else {
      changeValue();
    }
  }, [searchTerm, value, subjectValue, debounceFetchByClass]);

  const handleClassChange = async (e: any) => {
    setSubjectValue(null);
    setDropdownSubjects([]);
    setValue(e);
    const res = await getSubjectListByClassDropdown({ classId: e });
    setDropdownSubjects([
      { subjectName: "All Subjects", subjectId: null },
      ...res.subjects,
    ]);
  };

  const handleSubjectChange = async (e: any) => {
    setSubjectValue(e);
  };

  const _getAllClasses = async () => {
    setNewLoading("class");
    const res = await getAllClasses({ status: "active", withData: false });
    if (res) {
      setNewClassData(res?.data?.records || []);
    }
    setNewLoading("");
  };
  const _getAllStudents = async () => {
    setNewLoading("student");
    const res = await getAllStudentsByClass({ classId: newClassValue });
    if (res) {
      setNewStudentData(res?.students || []);
    }
    setNewLoading("");
  };
  const _getStudentSubject = async () => {
    setNewLoading("subject");
    const res = await getSubjectsStudent({
      studentId: newStudentValue,
      status: "active",
      page: 1,
      limit: 50000,
    });
    if (res) {
      setNewSubjectData(res?.result || []);
    }
    setNewLoading("");
  };

  useEffect(() => {
    _getAllClasses();
    fetchClasses();
  }, []);

  useEffect(() => {
    if (newClassValue !== undefined) {
      _getAllStudents();
    }
  }, [newClassValue]);
  useEffect(() => {
    if (newStudentValue !== undefined) {
      _getStudentSubject();
    }
  }, [newStudentValue]);

  const handleClassSelect = (event: any) => {
    setNewClassValue(event);

    newStudentData?.length > 0 && setNewStudentData([]);
    newStudentValue !== undefined && setNewStudentValue(undefined);

    newSubjectData?.length > 0 && setNewSubjectData([]);
    newSubjectValue !== undefined && setNewSubjectValue(undefined);
  };

  const handleStudentSelect = (event: any) => {
    setNewStudentValue(event);

    newSubjectData?.length > 0 && setNewSubjectData([]);
    newSubjectValue !== undefined && setNewSubjectValue(undefined);
  };
  const handleSubjectSelect = (event: any) => {
    setNewSubjectValue(event);
  };

  const reset = () => {
    newClassData?.length > 0 && setNewClassData([]);
    newClassValue !== undefined && setNewClassValue(undefined);

    newStudentData?.length > 0 && setNewStudentData([]);
    newStudentValue !== undefined && setNewStudentValue(undefined);

    newSubjectData?.length > 0 && setNewSubjectData([]);
    newSubjectValue !== undefined && setNewSubjectValue(undefined);
  };

  return checkRoutePermissions("monitoringPage") ? (
    <>
      <Modal
        open={isModalOpen}
        className="custom-modal"
        onCancel={handleCancel}
        footer={false}
      >
        <img src={addstudent} alt="logo" />
        <div className="modalheading">
          <h1>Add Student</h1>
        </div>
        <div className="form" style={{ width: "100%" }}>
          <Space
            direction="vertical"
            size="middle"
            style={{
              display: "flex",
            }}
          >
            <Select
              loading={newLoading === "class"}
              showSearch
              placeholder="Class name"
              style={{ width: "100%" }}
              optionFilterProp="children"
              value={newClassValue}
              options={newClassData
                ?.filter((v: any) => v?.status !== "archive")
                ?.map((v: any) => {
                  return {
                    value: v?.classId,
                    label: `${v?.className}`,
                  };
                })}
              filterOption={(input, option: any) =>
                (option?.label ?? "")
                  ?.toLowerCase()
                  .includes(input.toLowerCase())
              }
              onChange={handleClassSelect}
            />
            <Select
              loading={newLoading === "student"}
              showSearch
              placeholder="Student name"
              style={{ width: "100%" }}
              optionFilterProp="children"
              value={newStudentValue}
              options={newStudentData?.map((v: any) => {
                return {
                  value: v?.studentId,
                  label: `${v?.firstName} ${v?.lastName}`,
                };
              })}
              filterOption={(input, option: any) =>
                (option?.label ?? "")
                  ?.toLowerCase()
                  .includes(input.toLowerCase())
              }
              onChange={handleStudentSelect}
            />
            <Select
              loading={newLoading === "subject"}
              showSearch
              placeholder="Subject name"
              style={{ width: "100%" }}
              optionFilterProp="children"
              value={newSubjectValue}
              options={newSubjectData?.map((v: any) => {
                return {
                  value: v?.subjectId,
                  label: `${v?.subjectName}`,
                };
              })}
              filterOption={(input, option: any) =>
                (option?.label ?? "")
                  ?.toLowerCase()
                  .includes(input.toLowerCase())
              }
              onChange={handleSubjectSelect}
            />
          </Space>
          <Space style={{ width: "100%" }}>
            <div className="student_modal_button">
              <Button
                onClick={handleCancel}
                disabled={loading}
                className="cancelbtn"
              >
                Cancel
              </Button>
              <Button
                loading={loading}
                disabled={loading}
                onClick={submitData}
                htmlType="reset"
                className="addbtn"
              >
                Add
              </Button>
            </div>
          </Space>
        </div>
      </Modal>
      <Modal
        title="Delete Stuent"
        open={selectedStudentForDelete.showConfirmationModal}
        onOk={() => {
          setSelectedStudentForDelete({
            studentData: null,
            showConfirmationModal: false,
          });
          deleteMonitoringData(
            selectedStudentForDelete.studentData.monitoringId
          );
        }}
        onCancel={() =>
          setSelectedStudentForDelete({
            studentData: null,
            showConfirmationModal: false,
          })
        }
      >
        <p>Are you sure you want to delete this student?</p>
      </Modal>
      <div className="mainDiv responsive">
        <div style={{ height: "100%" }}>
          <div className="main_parent">
            <div>
              <GHeader
                heading="Monitoring"
                numberOfPeople={`${count ?? 0} People`}
                onClick={showModal}
                buttonText="Add a Student"
              />
              <div className="fliters_container">
                <Input
                  placeholder="Search..."
                  //@ts-ignore
                  value={searchTerm}
                  onChange={(e) => {
                    setSearchTerm(e.target.value);
                  }}
                  prefix={
                    <CiSearch
                      style={{
                        width: "20px",
                        height: "20px",
                        color: "#667085",
                      }}
                    />
                  }
                  className="filter_search"
                />

                <div className="select_container">
                  <Select
                    className="filter_select"
                    placeholder="Select a class"
                    value={value}
                    notFoundContent={
                      classDropdownLoad ? (
                        <div style={{ margin: "5px 0px" }}>
                          <Loading />
                        </div>
                      ) : undefined
                    }
                    onChange={handleClassChange}
                    optionFilterProp="children"
                    suffixIcon={
                      <RiExpandUpDownLine
                        style={{
                          width: "20px",
                          height: "20px",
                          color: "#667085",
                        }}
                      />
                    }
                  >
                    {classes?.length > 0 &&
                      classes.map((item: any) => {
                        return (
                          <Select.Option value={item.classId}>
                            {item.className}
                          </Select.Option>
                        );
                      })}
                  </Select>
                </div>
                <div className="select_container">
                  <Select
                    className="filter_select"
                    placeholder="Select a subject"
                    value={subjectValue}
                    disabled={dropdownSubjects.length === 0}
                    onChange={handleSubjectChange}
                    optionFilterProp="children"
                    suffixIcon={
                      <RiExpandUpDownLine
                        style={{
                          width: "20px",
                          height: "20px",
                          color: "#667085",
                        }}
                      />
                    }
                  >
                    {dropdownSubjects?.length > 0 &&
                      dropdownSubjects.map((item: any) => {
                        return (
                          <Select.Option value={item.subjectId}>
                            {item.subjectName}
                          </Select.Option>
                        );
                      })}
                  </Select>
                </div>
                <div className="select_container">
                  <Button
                    className="btn_clear"
                    onClick={() => {
                      getAllMonitoringData();
                      setSearchTerm(null);
                      setValue(null);
                      setSubjectValue(null);
                      setDropdownSubjects([]);
                    }}
                  >
                    Clear
                  </Button>
                </div>
              </div>
            </div>
            {loading ? (
              <Flex
                align="center"
                justify="center"
                style={{ height: "50vh" }}
                gap="middle"
              >
                <Spin size="large" />
              </Flex>
            ) : (
              <>
                <div className="mian_tab" style={{ marginTop: "10px" }}>
                  <div className="table_container">
                    <Table
                      className="custom_table_design"
                      dataSource={data}
                      columns={columns}
                      scroll={{ x: 600 }}
                      loading={loading}
                      pagination={false}
                    />
                  </div>
                </div>
                <div className="main_pagination">
                  <CustomPagination
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    total={count}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  ) : (
    <NotAccess />
  );
};

export default Monitoring;
