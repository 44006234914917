import React, { useEffect, useState } from "react";
import { Button, Row, Col, Tabs, TabsProps, Spin } from "antd";
import { FaRegCheckCircle } from "react-icons/fa";
import {
  getAllNotificationsData,
  markAsRead,
} from "../../store/services/notifications";
import moment from "moment";
import { useAppSelector } from "../../store/hooks";
import { getAllNotifications } from "../../store/interfaces/notifications/Notifications";
import CustomPagination from "../../components/Pagination/CustomPagination";
import { NotificationStatusIcon } from "../../config/NotificationIcon";
import { MdNotificationsActive } from "react-icons/md";
import { getNotificationsCount } from "../../store/services/notificationCounts";
import { checkRoutePermissions } from "../../utils/permission";
import NotAccess from "../NotAccess";
import { IoTrashOutline } from "react-icons/io5";
import { deleteNotification } from "../../store/services/announcement";

const Notification = () => {
  const { user } = useAppSelector((state) => state.auth);
  const { loadingGetAllNotifications, groupAllNotifications, total } =
    useAppSelector((state) => state.notification);
  const [unreadNotifications, setUnreadNotifications] = useState<
    getAllNotifications[]
  >([]);
  const { unreadCount } = useAppSelector((state) => state.notificationCount);
  const [all, setAll] = useState<getAllNotifications[]>([]);
  const [active, setActive] = useState("1");
  const [page, setPage] = useState<number>(1);
  const [loading, setLoading] = useState<number>(-1);

  const isAdmin = user?.role === "admin";
  const isTeacher = user?.role === "teacher";

  const onChange = (key: string) => {
    setActive(key);
    setPage(1);
  };

  const handleDeleteNoti = async (id: number) => {
    setLoading(id);
    const res = await deleteNotification(id);
    if (res) {
      getData();
    }
    setLoading(-1);
  };

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Unread",
      children: (
        <>
          <div className="notifications-tabs-content notification-custom-scrollbar">
            {unreadNotifications.length > 0 ? (
              unreadNotifications.map((data, index) => (
                <div className="notifications-bg" key={index}>
                  <div className="notificationRow">
                    <div className="noti-icon">
                      {data.type === ""
                        ? NotificationStatusIcon["general"]
                        : NotificationStatusIcon[data.type]}
                    </div>
                    <div className="noti-content">
                      <h4>{moment(data?.date).format("DD-MM-YYYY HH:mm")}</h4>
                      <p className="notification-text">{data?.message}</p>
                    </div>
                    <div className="noti-mark-btn">
                      <Button
                        className="notificationbtn notification-read"
                        icon={<FaRegCheckCircle className="mark-read" />}
                        onClick={() => handleSingleMarkasRead(data.id)}
                      >
                        Mark as Read
                      </Button>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <MdNotificationsActive className="bellNotification" />
                <h4>No Unread Notifications</h4>
              </div>
            )}
          </div>
        </>
      ),
    },
    {
      key: "2",
      label: "All",
      children: (
        <>
          <div className="notifications-tabs-content notification-custom-scrollbar">
            {all?.length > 0 ? (
              all?.map((data, index) => {
                // console.log("data===>", data);
                return (
                  <div
                    key={index}
                    className={
                      data.isRead === 1
                        ? "notifications-bg-unread"
                        : "notifications-bg"
                    }
                  >
                    <div className="notificationRow">
                      <div className="noti-icon">
                        {data.type === ""
                          ? NotificationStatusIcon["general"]
                          : NotificationStatusIcon[data.type]}
                      </div>
                      <div className="noti-content">
                        <h4>
                          Admin <div className="dot" />:{" "}
                          {moment(data?.date).format("DD-MM-YYYY HH:mm")}
                        </h4>
                        <p className="notification-text">{data?.message}</p>
                      </div>
                      {data.isRead !== 1 && (
                        <div className="noti-mark-btn">
                          <Button
                            className="notificationbtn notification-read"
                            icon={<FaRegCheckCircle className="mark-read" />}
                            onClick={() => handleSingleMarkasRead(data.id)}
                          >
                            Mark as Read
                          </Button>
                        </div>
                      )}
                      {isAdmin ? (
                        <div className="noti-delete-icon">
                          {loading === data.id ? (
                            <Spin />
                          ) : (
                            <IoTrashOutline
                              onClick={() => handleDeleteNoti(data?.id)}
                            />
                          )}
                        </div>
                      ) : (
                        <>
                          {isTeacher &&
                            data.notificationType === "specific" && (
                              <div className="noti-delete-icon">
                                {loading === data.id ? (
                                  <Spin />
                                ) : (
                                  <IoTrashOutline
                                    onClick={() => handleDeleteNoti(data?.id)}
                                  />
                                )}
                              </div>
                            )}
                        </>
                      )}
                    </div>
                  </div>
                );
              })
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "60vh",
                }}
              >
                <MdNotificationsActive size={80} />
                <h4>No New Notifications</h4>
              </div>
            )}
          </div>
        </>
      ),
    },
  ];

  const getData = async () => {
    const pageNumber = page;
    const limit = 5;
    const status = active === "1" ? "unread" : "all";
    try {
      await getAllNotificationsData(pageNumber, limit, status);
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  };

  const extractNotificationsData = () => {
    const allNotificationData = groupAllNotifications;
    const unreadNotificationData = groupAllNotifications.filter(
      (item: getAllNotifications) => item?.isRead === 0
    );
    setAll(
      allNotificationData
      // ?.slice()
      // ?.sort(
      //   (a: any, b: any) =>
      //     new Date(b?.date).getTime() - new Date(a?.date).getTime()
      // )
    );
    setUnreadNotifications(
      unreadNotificationData
      // ?.slice()
      // ?.sort(
      //   (a: any, b: any) =>
      //     new Date(b?.date).getTime() - new Date(a?.date).getTime()
      // )
    );
  };

  useEffect(() => {
    extractNotificationsData();
  }, [groupAllNotifications]);

  const markAllAsRead = async () => {
    const payload = {
      ids:
        unreadNotifications?.length > 0 &&
        unreadNotifications?.map((item) => item.id),
    };
    const res = await markAsRead(payload);
    if (res) {
      getData();
      await getNotificationsCount();
    }
  };

  const handleSingleMarkasRead = async (value: any) => {
    const payload = {
      ids: [value],
    };
    const res = await markAsRead(payload);
    if (res) {
      getData();
      await getNotificationsCount();
    }
  };

  useEffect(() => {
    getData();
  }, [page, active, unreadCount]);

  return checkRoutePermissions("notificationPage") ? (
    <div className="notificationContainer responsive">
      <div className="main_parent" style={{ height: "100%" }}>
        <div>
          <div className="notifications-btn-parent">
            <h2 className="notificationHeading">Notifications</h2>
            <div>
              {active === "1" && (
                <Button
                  className="notificationbtn"
                  icon={<FaRegCheckCircle className="mark-read" />}
                  onClick={markAllAsRead}
                >
                  Mark all as Read
                </Button>
              )}
            </div>
          </div>
          <p className="notification_text" style={{ marginBottom: "10px" }}>
            You have {unreadNotifications.length} notifications to go through.
          </p>
          <hr />
        </div>
        <div className="mian_tab" style={{ height: "100%" }}>
          {loadingGetAllNotifications ? (
            <div className="loadingGIF" style={{ height: "100%" }}>
              <img
                width={60}
                height={50}
                src={require("../../assets/loadingGrid.gif")}
                alt="loading"
              />
              <p>Loading....</p>
            </div>
          ) : (
            <div style={{ height: "100%" }}>
              <Tabs
                defaultActiveKey="1"
                activeKey={active}
                items={items}
                onChange={onChange}
              />
            </div>
          )}
        </div>
        {active === "2" && (
          <div className="main_pagination">
            <CustomPagination
              currentPage={page}
              setCurrentPage={setPage}
              total={total}
            />
          </div>
        )}
      </div>
    </div>
  ) : (
    <NotAccess />
  );
};

export default Notification;
